import React, { useState } from 'react';
import { Box, Grid, Typography, Paper, Button, CardMedia, Divider, Stack } from "@mui/material";
import { FaUserDoctor } from "react-icons/fa6";
import moment from 'moment/moment';
import { IoMdSchool } from "react-icons/io";
import { GrCertificate } from "react-icons/gr";
import StethIcon from '../../assets/images/Experience.svg';
import { BiSolidPhone } from "react-icons/bi";
import { CiMail } from "react-icons/ci";
import YouTubeIcon from '../../assets/images/Icon fa-brands-youtube.svg'
import InstagramIcon from '../../assets/images/Icon corebrands-instagram.svg'
import TwiterIcon from '../../assets/images/Icon fa-brands-x-twitter.svg'
import LinkedInIcon from '../../assets/images/Icon corebrands-linkedin.svg'
import QrCode from '../../assets/qr.png'
import { CgOrganisation } from "react-icons/cg";
import contactIcon from '../../assets/images/contact-card-link.svg';
import hprIcon from '../../assets/images/hpr.svg'

const Overview = ({ userData, doctorData }) => {
  const [qualseemore, setQualseemore] = useState(() => { return false; });
  const [seemore, setSeemore] = useState(() => { return false; });
  const [expanded, setExpanded] = useState(false);

  const handleDoctorsExp = (data, limit) => {
    return (
      data?.slice(0, limit).map((e, i) => (
        <Box key={i} sx={{ display: 'flex', gap: 2, mt: 3 }}>
          <Box>
            {
              e?.logo?.length > 0 ?
                <img src={e.logo} alt="org" width={65} height={65} style={{ objectFit: 'cover', borderRadius: '5px' }} />
                :
                <Box >  <CgOrganisation style={{ fontSize: '40px' }} /> </Box>
            }
          </Box>
          <Box>
            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 16 }, fontWeight: 600 }}>{e.organisationName || e.unofficialOrganisationName}</Typography>

            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 }, fontWeight: 600 }}>{e.designation}</Typography>
            {
              e?.city?.length > 0 ?
                <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{e.city}</Typography>
                :
                <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>N/A</Typography>
            }
            {
              e?.fromDate?.length > 0 ?
                <Box sx={{ display: 'flex' }}>
                  {/* <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}>{e.fromDate}</Typography>
                                {
                                    e.toDate?.length > 0 ?
                                        <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}> - {e.toDate}</Typography>
                                        :
                                        <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}> - Present</Typography>
                                } */}
                  <Typography variant="body2" sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{moment(e.fromDate).format('MMM yyyy')} - {e.toDate ? moment(e.toDate).format('MMM yyyy') : 'present'}</Typography>
                </Box>
                :
                null
            }
          </Box>
        </Box >
      ))
    );
  };
  const handleseemore = () => {
    setSeemore(!seemore);
  };

  const handledoctorQual = (data, limit) => {
    return (
      <>
        {
          data.slice(0, limit).map((e, i) => (
            <Box key={i} sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 1 }}>
              {e.logo ?
                <img className="expImg" src={e.logo} height="56" width="56" alt="exp" />
                :
                <CgOrganisation style={{ fontSize: '40px' }} />
                // <img src={''} alt="org" width={65} height={65} />

              }
              <Box>
                <Box sx={{ display: 'flex', gap: "4px" }} className="items-center">
                  {/* <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 }, fontWeight: 600 }}>{e.code.coding[0].code}</Typography>- */}
                  <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{e.code?.text}</Typography>
                </Box>
                {
                  e?.issuer?.display ?
                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{e.issuer?.display}</Typography>
                    :
                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }} >N/A</Typography>
                }
                {
                  e?.period?.start?.length > 0 ?
                    <Box sx={{ display: 'flex' }} >
                      {/* <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}>{moment(e.period.start).format('YYYY-MM-DD')}</Typography>
                                        {
                                            e.period?.end?.length > 0 ?
                                                <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}>-{moment(e.period.end).format('YYYY-MM-DD')}</Typography>
                                                :
                                                <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}> - Present</Typography>
                                        } */}

                      <Typography variant="body2" sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{moment(e.fromDate).format('MMM yyyy')} - {e.toDate ? moment(e.toDate).format('MMM yyyy') : 'present'}</Typography>
                    </Box>
                    :
                    null
                }
              </Box>
            </Box>
          ))
        }
      </>
    );
  };
  const handleQualseemore = () => {
    setQualseemore(!qualseemore);
  };
  return (
    <div>
      <Paper sx={{ px: 2, py: 1 }}>
        <Grid className='flex gap-3 items-center text-base'> <FaUserDoctor style={{ color: '#205072' }} /> <span style={{ color: '#205072' }} className='text-[18px] font-semibold  '>About</span> </Grid>
        <div>
          <Grid className='pl-7 pt-3 text-xs' style={{ color: '#707070' }}>
            <Typography
              component={"p"}
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: expanded ? "none" : 2, // Shows 3 lines when collapsed
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: '14px',
                lineHeight: 1.8,
                wordBreak: 'break-word', // Prevents horizontal overflow by breaking long words

              }}
            >
              {doctorData?.about || 'N/A'}

            </Typography>
            {doctorData?.about?.length > 80 ? (

              <Typography
                component={"span"}
                onClick={() => setExpanded(!expanded)}
                sx={{ cursor: "pointer", fontSize: 14, color: "blue" }}
              >
                {expanded ? "Read Less" : "Read More"}
              </Typography>
            ) : (
              ""
            )}

          </Grid>
        </div>
        {/* <Grid className='pl-7 pt-3 text-xs' style={{ color: '#707070' }}>{doctorData?.about ? doctorData?.about : 'N/A'}</Grid> */}
      </Paper>
      <Paper sx={{ px: 2, py: 1, mt: 4 }}>
        <Grid className='flex gap-3 items-center text-base'> <IoMdSchool style={{ color: '#205072' }} /> <span style={{ color: '#205072' }} className='text-[18px] font-semibold  '>Qualification</span> </Grid>
        <Grid className='pl-7 pt-3 text-xs' style={{ color: '#707070' }}>
          {userData?.qualification?.length > 0 ? (

            <>
              {handledoctorQual(
                userData?.qualification?.filter(qual =>
                  !qual.code.coding.some(code => code.code === "CER" || code.code === "LN")
                ),
                qualseemore === false ? 3 : qualseemore.length
              )}

            </>
          ) : (
            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>No Qualification</Typography>
          )}
          {userData?.qualification?.length > 3 ? (
            <Box onClick={handleQualseemore}>
              <Typography>
                {" "}
                {qualseemore == false ? (
                  <Typography>seemore</Typography>
                ) : (
                  <Typography>seeless</Typography>
                )}{" "}
              </Typography>
            </Box>
          ) : null}
        </Grid>
      </Paper>
      <Paper sx={{ px: 2, py: 1, mt: 4 }}>
        <Grid className='flex gap-3 items-center text-base'>
          <img
            src={StethIcon}
            width={20}
            height={20}
            alt="stethoscope"
            sx={{ fontSize: "12px" }}
          />
          <span style={{ color: '#205072' }} className='text-[18px] font-semibold'>Experience</span> </Grid>
        <Grid className='pl-7 pt-3 text-xs' style={{ color: '#707070' }}>
          {doctorData?.experience?.length > 0 ? (
            <Box >
              {handleDoctorsExp(doctorData?.experience)}
            </Box>
          ) : (
            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>
              No Experience Information
            </Typography>
          )}
        </Grid>
      </Paper>
      <Paper sx={{ px: 2, py: 1, mt: 4 }}>
        {/* <Grid className='flex gap-3 items-center text-base'> <GrCertificate style={{ color: '#205072' }} /> <span style={{ color: '#205072' }} className='text-[18px] font-semibold'>Certification & Licences</span> </Grid> */}
        <Grid className='flex gap-3 items-center text-base'> <GrCertificate style={{ color: '#205072' }} /> <span style={{ color: '#205072' }} className='text-[18px] font-semibold'>Certification & Licences</span> </Grid>
        <Grid className='pl-7 pt-3 text-xs' style={{ color: '#707070' }}>
          {userData?.qualification?.length > 0 ? (

            <>
              {handledoctorQual(
                userData?.qualification?.filter(qual =>
                  qual.code.coding.some(code => code.code === "CER" || code.code === "LN")
                ),
                qualseemore === false ? 3 : qualseemore.length
              )}

            </>
          ) : (
            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>No Certificate and Licence </Typography>
          )}
        </Grid>
      </Paper>
      <Paper sx={{ px: 2, py: 1, mt: 4 }}>
        <Grid className='flex gap-3 items-center text-base'>  <img src={contactIcon} width={18} height={18} /> <span style={{ color: '#205072' }} className='text-[18px] font-semibold'>Contact Details</span> </Grid>
        <Grid className='pl-7 pt-3 text-xs' justifyContent={"space-between"} container>
          <Grid item spacing={2}>
            {/* {console.log(userData?.telecom?.filter((res) => res?.system === "phone"), 196)} */}
            <Grid className='flex gap-3' ><BiSolidPhone style={{ color: '#205072', border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} /> <span style={{ color: '#205072' }}>Phone</span>
              <span style={{ color: '#3F3FD5' }}>{userData?.telecom?.filter((res) => res?.use === "work" && res?.system === "phone")?.[0]?.value || 'Private'}</span>
            </Grid>
            <Grid className='flex gap-3 pt-2' ><CiMail style={{ color: '#205072', border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} /> <span style={{ color: '#205072' }}>Email &nbsp;</span>
              <span style={{ color: '#3F3FD5' }}>{userData?.telecom?.filter((res) => res?.system === "email")?.[0]?.value || 'N/A'}</span>
            </Grid>
            <Grid className='flex gap-3 pt-2' >
              {/* <CiMail style={{ color: '#205072', border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} /> */}
              <div style={{ padding: '1px', marginLeft: '13px' }}></div>
              <span style={{ color: '#205072' }}>Follow &nbsp;</span>
              <span style={{ color: '#3F3FD5', display: 'flex' }}>
                <img src={TwiterIcon} alt="" width={16} height={16} style={{ marginRight: "8px", height: "20px" }} />
                <img src={InstagramIcon} alt="" width={16} height={16} style={{ marginRight: "8px", height: "20px" }} />
                <img src={YouTubeIcon} alt="" width={16} height={16} style={{ marginRight: "8px", height: "20px" }} />
                <img src={LinkedInIcon} alt="" width={16} height={16} style={{ marginRight: "8px", height: "20px" }} />
              </span>
            </Grid>
            {/* <Grid>
              <Grid className='text-xs pt-2'> Follow</Grid>
              <Grid container sx={{ display: "flex", mb: 2, }} spacing={2} >
                <Grid item xs={2} sm={2} md={2}>
                  <img src={TwiterIcon} alt="" width={16} height={16} style={{ marginRight: "8px", height: "20px" }} />
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <img src={InstagramIcon} alt="" width={16} height={16} style={{ marginRight: "8px", height: "20px" }} />
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <img src={YouTubeIcon} alt="" width={16} height={16} style={{ marginRight: "8px", height: "20px" }} />
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <img src={LinkedInIcon} alt="" width={16} height={16} style={{ marginRight: "8px", height: "20px" }} />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs={4} >
            <Grid className='flex gap-2 pt-2'><img src={hprIcon} style={{ width: '15px', height: '15px' }} /> <span className='text-xs'>NHPR ID</span>
              <span style={{ color: '#3F3FD5' }}>{userData?.identifier?.[2]?.value}</span>

            </Grid>
            <Grid className='pt-2 flex items-center gap-3'>
              <Grid>
                <Box style={{ width: '50px', height: '50px' }} className="border border-inherit">
                  <img src={QrCode} />
                </Box>
              </Grid>
              <Grid className='text-xs text-center'>Scan QR code in Mobile To book appointment with this Practitioner</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default Overview