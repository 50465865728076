import React, { useEffect, useRef, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Typography, useMediaQuery } from '@mui/material'
import { Close } from '@mui/icons-material'
import { CustomTextField } from '../atoms/TextField'
import { OutlinedButton } from '../atoms/commonbutton'
import * as yup from 'yup';
import URL from '../../services/api_url';
import axios from 'axios';
import { Formik } from 'formik';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import LoginBgImg from '../../assets/LOGIN PAGE IMAGE.svg';
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSelector } from 'react-redux'


const ForgotUser = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState(null)
    const [sendOtp, setSendOtp] = useState(false)
    const [showusername, setShowUsername] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [loading, setLoading] = useState(false)
    const formikRef = useRef(null);  // Create a ref to store formik's resetForm function
    const ismobile = useMediaQuery('(min-width:900px)');
    const [captchToken, setCaptchToken] = useState(null)
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)


    const signUpValidationSchema = yup.object().shape({
        emailId: yup.string().required('Email is required')
            .test('valid-domain', 'Invalid Email', (value) => {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation
                return emailPattern.test(value);
            }),
    })
    // useEffect(() => {
    //     const script = document.createElement("script");
    //     script.src =
    //         "https://www.google.com/recaptcha/api.js?render=6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-";
    //     document.body.appendChild(script);
    // }, []);
    const handleChangeEmail = (e, setFieldTouched, setFieldValue, setFieldError) => {
        const { value } = e.target;
        setFieldValue('emailId', value);
        setFieldTouched('emailId', true, false); // S
        setFieldError('emailId', '');
        setAlertmessage('')
    }


    // const handleSendEmail = async (username, email) => {
    //     // if (phoneNumber.length === 10 && verifyOtp.length === 6) {

    //     //     // Perform OTP verification logic here...
    //     //     setOtpSubmitted(true); // Set OTP submission status to true
    //     // } else {
    //     //     console.log("Please enter a valid phone number and OTP.");
    //     // }
    //     // setVerifyOpen(true)
    //     // setRequestOpen(false)
    //     const data = {
    //         "messagetype": "OTP",
    //         "apptype": "paramitha",
    //         "phoneNumber": '',
    //         "email": email,
    //         "realm": 'parimitha',
    //         "subject": "paramitha",
    //         "username": username
    //     }
    //     // timeinterval();
    //     setLoading(true)
    //     window.grecaptcha.ready(_ => {
    //         window.grecaptcha
    //             .execute("6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-", { action: "submit" })
    //             .then(token => {
    //                 // console.log(token, "pythontokennnn")
    //                 axios.post(`${URL.otp}forgotusername/paramitha`, data, {
    //                     headers: {
    //                         "Authorization": `Bearer ${token}`,
    //                     }
    //                 }).then(res => {
    //                     if (res.status === 200 || res.status === 201) {
    //                         // setAlertmessage("please check your email")
    //                         // setAlertstatus('success')
    //                         alert('please check your Email')
    //                         handleDonePage()
    //                     }

    //                 }).catch((error) => {

    //                     setAlertmessage(error.message)
    //                     setAlertstatus('error')
    //                 }).finally(() => {
    //                     setLoading(false)
    //                 })
    //             })
    //     })

    // }
    const handleRecaptchaChange = (value) => {
        setCaptchToken(value)
        setAlertmessage('')
    }
    const handleSendEmail = async (username, email) => {
        const data = {
            "messagetype": "OTP",
            "apptype": "paramitha",
            "phoneNumber": '',
            "email": email,
            "realm": 'parimitha',
            "subject": "paramitha",
            "username": username
        }
        if (captchToken) {
            setLoading(true)

            axios.post(`${URL.otp}forgotusername`, data, {
                headers: {
                    'Authorization': `Bearer ${captchToken}`
                }
            }).then(res => {
                if (res.status === 200 || res.status === 201) {
                    setAlertmessage('OTP send successfully')
                    setAlertstatus('success')
                    handleDonePage()
                }
            }).catch((error) => {
                const errorMessage = error.response ? error.response.data.msg : "An error occurred while sending OTP.";

                setAlertmessage(errorMessage)
                setAlertstatus('error')
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setAlertmessage('Please verify the reCAPTCHA!')
            setAlertstatus('error')
        }
    }


    const handleVerifyOtp = () => {
        setShowUsername(true)
    }

    const handleDonePage = () => {
        setAlertmessage('')
        // setForgotuserOpen(false)
        navigate(selectedbranch?.name ? `/${selectedbranch?.name}/login` : '/login')
        if (formikRef.current) {
            formikRef.current.resetForm();  // Reset the form when the dialog is closed
        }
    }

    return (
        <div style={{ backgroundColor: "#EC7AB3", }}>
            <Grid container>
                <Grid item xs={12} sm={12} md={7}>
                    <Box sx={{
                        padding: { xs: "20px", sm: "20px 40px", md: "20px 40px" },
                        // backgroundColor: "#1B5775", // Assuming you want a background color for the left section
                    }}>
                        <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 20 }, color: "white", textTransform: "capitalize" }}>
                            Manage Your Appointments, Prescriptions <br />Medical Records, Test Reports & More
                        </Typography>
                        {ismobile && (
                            <Box mt={2}>
                                <img src={LoginBgImg} alt="login" style={{ width: '100%' }} />
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <Box sx={{
                        padding: { xs: "20px 10px", sm: "20px 40px", md: "20px 40px" },
                    }}>
                        <Paper elevation={3} sx={{ padding: { xs: "20px 20px", sm: '20px 20px', md: '20px 20px', lg: '20px 40px' } }}>
                            <Formik
                                validationSchema={signUpValidationSchema}
                                enableReinitialize={true}
                                initialValues={{ emailId: "" }}
                                onSubmit={async (values) => {
                                    const data = {
                                        "email": values.emailId,
                                        "realm": 'parimitha'
                                    }
                                    try {
                                        const res = await axios.post(`${URL.check}/findPatientsByUserNameviaEmail`, data, { headers: { realm: 'parimitha' } })
                                        handleSendEmail(res.data, values.emailId)
                                    } catch (error) {
                                        // console.log(error.response.data, "erroremail")
                                        setAlertmessage(error.response.data)
                                        setAlertstatus('error')
                                    }
                                }}
                                innerRef={formikRef}  // Store the formik instance in the ref

                            >
                                {
                                    ({
                                        handleSubmit,
                                        isValid,
                                        values,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        touched,
                                        isSubmitting,
                                        setFieldValue,
                                        setFieldError,
                                        setFieldTouched
                                    }) => (
                                        <>
                                            <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 18 }, textTransform: "capitalize", color: "#155775", fontWeight: 600 }}>
                                                Forgot User Name
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={12} md={12}>
                                                    <Box sx={{ mt: 2 }}>
                                                        <Box sx={{ mb: 1 }}>
                                                            <label style={{ fontWeight: 500 }}>Email Address</label>
                                                        </Box>
                                                        <CustomTextField
                                                            placeholder="Enter Email Address"
                                                            type={'email'}
                                                            borderColor="#59D8FA"
                                                            borderRadius="7px"
                                                            value={values.emailId}
                                                            onChange={
                                                                (e) => handleChangeEmail(e, setFieldTouched, setFieldValue, setFieldError)
                                                            }
                                                            handleBlur={handleBlur('emailId')}
                                                            error={touched.emailId && Boolean(errors.emailId)}
                                                        />
                                                        {errors.emailId && touched.emailId && (
                                                            <Typography sx={{ color: '#D32F2F', fontSize: '14px', mt: '1px' }}>
                                                                {touched.emailId && errors.emailId}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                    <Box sx={{ mt: 4, pl: "15px", transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                                        <ReCAPTCHA
                                                            sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                                            onChange={handleRecaptchaChange}
                                                            size="normal"
                                                        // style={{ transform: 'scale(0.9)' }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>


                                            <div className='text-center'> <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                                            <div className='mt-3 flex justify-end'>
                                                <OutlinedButton text={"Send User Name "} height="33px" fontSize="14px" borderColor="#1B5775" color="#1B5775" borderRadius="7px" onClick={handleSubmit} endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} />
                                            </div>
                                        </>
                                    )}

                            </Formik>


                        </Paper>
                    </Box>
                </Grid>

            </Grid >


        </div >
    )
}

export default ForgotUser