import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import GoogleMapReact from 'google-map-react';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';

const LocationIcon = () => (
    <div
        style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: 0,
            left: 0,
        }}
    >
        <LocationOnSharpIcon sx={{ color: 'maroon', fontSize: '40px' }} />
    </div>
);

const Location = ({ loc, setLoc, openMap, setOpenMap }) => {
    const [adjust, setAdjust] = useState(false);

    // Initialize map when loc changes
    useEffect(() => {
        if (loc.lat && loc.lng) {
            setOpenMap(true); // Open map when location is available
        }
    }, [loc]);

    const handleCoords = (t) => {
        setLoc({ lat: t.lat, lng: t.lng });
    };

    return (
        <Grid>
            {openMap && // Show map only when openMap is true
                <div style={{ height: '222px', width: '100%', position: 'relative', padding: "0px 0px", borderRadius: '2px', marginTop: '15px', border: '1px solid gray' }}>
                    <Button className="capitalize" sx={{ position: 'absolute', top: 5, right: 70, zIndex: '99999', backgroundColor: 'white!important', color: 'black', px: 4 }} size="small" variant="contained" onClick={() => setAdjust(true)}>Adjust</Button>

                    <GoogleMapReact
                        bootstrapURLKeys={{ key: `${process.env.REACT_APP_LOCATION_KEY}` }} // Replace with your API key
                        defaultCenter={loc}
                        defaultZoom={11}
                        onClick={handleCoords}
                        zoom={adjust ? 20 : 11}
                    >
                        <LocationIcon lat={loc.lat} lng={loc.lng} />
                    </GoogleMapReact>
                </div>
            }
        </Grid>
    );
};

export default Location;
