import React, { useState } from 'react'
import { Box, Card, Container, Grid, Typography, Button, CircularProgress, Checkbox } from "@mui/material"

import { useNavigate } from "react-router-dom"




const SignUpRoute = () => {
  return (
    <Box>SignupRoute</Box>
  )
}

export default SignUpRoute;