import React from 'react';
import { Box, Typography } from '@mui/material';
import Linkify from 'react-linkify';

function FeedText({ docFeed }) {
    return (
        <Typography sx={{ p: "0px 10px", fontSize: 14, height: { md: 300, lg: 430 } }}>
            <Box
                sx={{
                    overflowY: 'scroll',
                    maxHeight: {
                        xs: 'calc(100vh - 100px)',
                        sm: 'calc(100vh - 100px)',
                        md: 'calc(65vh - 220px)',
                        lg: 'calc(65vh - 80px)',
                    },
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    mb: 1,
                }}
            >
                <Typography
                    component={"p"}
                    sx={{
                        display: "-webkit-box",
                        fontSize: '16px',
                        lineHeight: 1.8,
                        mt: 1,
                    }}
                >
                    {/* Wrap the description text with Linkify */}
                    <Linkify
                        componentDecorator={(href, text, key) => (
                            <a
                                href={href}
                                key={key}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: 'none', // Remove underline by default
                                    color: 'blue', // Default link color
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.textDecoration = 'underline'; // Add underline on hover
                                    e.target.style.cursor = 'pointer'; // Change cursor on hover
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.textDecoration = 'none'; // Remove underline when not hovering
                                }}
                            >
                                {text}
                            </a>
                        )}
                    >
                        {docFeed?.description}
                    </Linkify>
                </Typography>
            </Box>
        </Typography>
    );
}

export default FeedText;
