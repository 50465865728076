import React, { useState } from "react";
import { Box, CardMedia, Typography } from "@mui/material";

import { OutlinedButton } from "../../atoms/commonbutton";

const DoctorEvent = ({ docFeed }) => {

    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        // setExpanded(true);
    };

    return (
        <div
            style={{
                // border: "1px solid lightgrey",
                color: "black",
                // margin: "10px",
                // marginBottom: "10px",
                // height: "100%",
                position: 'relative'
            }}
        >
            <div
                style={{
                    // padding: "10px",
                    //   border: "1px solid red",
                    //   height: "300px"
                }}
            >
                {/* description */}
                <Typography sx={{ p: "0px 10px", fontSize: 14, fontWeight: 500, height: 50 }}>
                    {/* {docFeed?.description} */}

                    {docFeed?.description ? (
                        <>
                            {expanded
                                ? docFeed.description
                                : `${docFeed.description.slice(0, 75)}`}
                            {docFeed.description.length > 80 ? (
                                <Typography
                                    component={"span"}
                                    onClick={handleExpandClick}
                                    sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                                >
                                    {expanded ? "...see less" : "...see more"}{" "}
                                </Typography>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <Box sx={{ height: { sm: 63, md: 42 } }}></Box>
                    )}
                </Typography>
                {/* image */}
                <div className="mt-0.5">
                    {docFeed.eventData.eventImage ? (
                        <CardMedia
                            className="object-fill"
                            component="img"
                            // height="194"
                            sx={{
                                height: { xs: "150px", sm: "200px", md: "220px" },
                                //   border: "1px solid red",
                            }}
                            src={docFeed.eventData.eventImage}
                            alt=""
                        />
                    ) : (
                        <CardMedia
                            className="object-fill"
                            component="img"
                            // height="194"
                            sx={{
                                height: { xs: "150px", sm: "200px", md: "220px" },
                                //   border: "1px solid red",
                            }}
                            src={"https://media.istockphoto.com/id/1396814518/vector/image-coming-soon-no-photo-no-thumbnail-image-available-vector-illustration.jpg?s=612x612&w=0&k=20&c=hnh2OZgQGhf0b46-J2z7aHbIWwq8HNlSDaNp2wn_iko="
                            }
                            alt="soon"
                        />
                    )}
                </div>

                {/* event details */}
                {/* <Box
          sx={{
            p: "10px",
            borderRadius: "10px",
            border: "1px solid gray",
            marginTop: "10px",
          }}
        >
          <Box
            style={{ padding: "10px", backgroundColor: "#e8e8e0" }}
            className="newcontact-fieldheader"
          >
            <div className="overflow_hidden">
              <Typography
                className="d-flex e-align color_head font-bold cursor capitalize"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                // onClick={() =>
                //   data.link
                //     ? console.log("")
                //     : docFeed.id && navigate(`/webinar/eventdata-${data.id}`)
                // }
              >
                <CalendarMonthIcon />
                &nbsp;
                <span className="text-ellipsis">{docFeed.eventData.title}</span>
                &nbsp;
                <span className="text-ellipsis">
                  <Typography>{docFeed?.title}</Typography>
                </span>
              </Typography>
              <Typography sx={{ mt: "2px" }}>
                {moment(docFeed?.openingTime).format("DD/MM/YYYY")} -{" "}
                {moment(docFeed?.eventData?.closingTime).format("DD/MM/YYYY")}
              </Typography>
            </div>
          </Box>
        </Box> */}
                <div style={{
                    position: 'absolute', backgroundColor: "#F6F6F7",
                    bottom: 0, left: 0, right: 0, padding: "10px 20px",
                    opacity: 0.9,

                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                        className="d-flex cursor"
                    >
                        <div style={{ color: '#1D5775', fontWeight: 600, fontSize: 14 }}>Event</div>
                        <OutlinedButton text="Register" borderRadius="20px" height="25px" fontSize="13px" borderColor="#1D5775" color="#1D5775" />
                    </div>
                    <div className="fntsz-13 mt-0.5 font-medium">Free Health camp for Family Health, Woman and child health</div>
                </div>
            </div>
        </div>
    );
};

export default DoctorEvent;
