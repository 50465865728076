import React, { useState } from 'react'
import { Grid, Paper, Stack, Typography, CardMedia, Button, Box, Tab, Tabs, Divider, Dialog } from '@mui/material'
import maleDoctorImg from '../../assets/images/MaleDoctor-no-photo.svg';
import FemaleDoctorImg from '../../assets/images/FemaleDoctor-No Photo.svg';
import StethIcon from '../../assets/images/stethoscope.svg';
import followIcon from '../../assets/images/Follow.svg';
import CommunicationImg from '../../assets/images/Communication-language.svg';
import { ContentCopy, Email, Facebook, LinkedIn, ShareOutlined, Twitter, WhatsApp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Overview from './Overview';
import MedicalService from './Medicalservice';
import PostPublications from './PostPublications';
import { WhatsappShareButton, EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import './doctor.css'
import { Helmet } from 'react-helmet-async';

const CustomButton = (props) => {
    return (
        <Button className="add-option" sx={{ mt: '0px!important', mr: 2, fontWeight: '550!important', padding: '5px 12px!important', color: '#E44190' }} variant="outlined">{props.children}&nbsp;{props.name}</Button>
    )
}


const CustomRow = (props) => {
    return (
        <Typography component="div" sx={{ px: 2, pt: 2 }} >
            <Typography component="p" sx={{ pb: 1 }} className="flex items-center">
                {props.children}
            </Typography>
            {props.true ? '' : <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />}
        </Typography>
    )
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3, overflowY: 'scroll', height: 'calc(100% - 480px)', paddingLeft: '1px', paddingRight: '1px' }} className="doctorprofiletab">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
function DocProfileHeader({ practitionerrole, userData, doctorData, id, selectedbranch }) {
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const title = userData?.name?.[0]?.text
    const url1 = `https://www.paramithahospitals.in/${selectedbranch?.name ? `${selectedbranch?.name?.replace(/[\s,]+/g, '%20')}/doctor/${id}` : `/doctor/${id}`}`;
    const HandleCopy = () => {
        navigator.clipboard.writeText(url1)

        // const baseUrl =
        //     process.env.NODE_ENV === 'development'
        //         ? 'http://localhost:3000/' // Local development URL
        //         : process.env.REACT_APP_WEB_URL; // Production URL from environment variables

        // const copyText = `${baseUrl}${selectedbranch?.name ? `${selectedbranch?.name?.replace(/\s+/g, '%20')}/doctor/${id}` : `/doctor/${id}`
        //     } `;

        // navigator.clipboard.writeText(copyText)
        //     .then(() => {
        //         console.log('URL copied to clipboard:', copyText);
        //     })
        //     .catch((err) => {
        //         console.error('Failed to copy URL: ', err);
        //     });
    };


    const handleClose = () => {
        setOpen(false)
    }
    return (
        <div>
            <Helmet>
                <title>{`${userData?.name?.[0]?.text}-doctor | Paramitha Hospitals `}</title>
                {/* <meta name="description" content={state.about} /> */}
                <meta name="canonical" content={url1} />
                <meta name="keywords" content={`${userData?.name?.[0]?.text}, "Paramitha Hospitals", ${userData?.name?.[0]?.text} `} />
                <meta property="image" content={userData?.photo?.[0]?.url ? userData?.photo?.[0]?.url : userData?.gender === "female" ? 'https://paramith-website-contents.s3.ap-south-1.amazonaws.com/FemaleDoctor-NoPhoto.svg' : 'https://paramith-website-contents.s3.ap-south-1.amazonaws.com/MaleDoctor-no-photo.svg'} />
            </Helmet>
            <Paper className='p-2'>
                <Grid item container xs={12} sm={8} md={8} spacing={2}>
                    <Grid item>
                        <CardMedia
                            sx={{ height: 90, width: 90, border: '3px solid #fff', borderRadius: '12px', padding: "3px", background: '#C6C6C6' }}
                            className="shadow-2xl"
                            component="img"
                            alt="gdoctor img"
                            width={130}
                            height="140"
                            image={userData?.photo?.[0]?.url ? userData?.photo?.[0]?.url : userData?.gender === 'female' ? FemaleDoctorImg : maleDoctorImg} />


                    </Grid>
                    <Grid item>
                        <Stack direction={"column"} spacing={0.2}>
                            <Typography sx={{ color: "#000000", textTransform: 'capitalize', fontSize: "18px", fontWeight: 500, }} >
                                {userData?.name?.[0]?.text}
                            </Typography>
                            <Typography sx={{ color: "#707070", fontSize: "11px", }} >
                                {/* {userData?.name?.[0]?.suffix?.length > 0 ?
                                    userData?.name?.[0]?.suffix?.map((item, index) =>
                                        <div key={index}>
                                            {item}
                                        </div>
                                    )
                                    :
                                    // 'MBBS, MD, DCH'
                                    ''
                                } */}
                                {userData?.name?.[0]?.suffix?.length > 0 ? (
                                    userData?.name?.[0]?.suffix?.map((item, index) => (
                                        <span key={index}>
                                            {item}{index < userData?.name[0]?.suffix?.length - 1 ? ', ' : ''}
                                        </span>
                                    ))
                                ) : null}
                            </Typography>
                            <Typography sx={{ color: "#000000", textTransform: 'capitalize' }} className="text-sm" >
                                {/* {practitionerrole?.resource?.identifier?.map((option) =>
                                    <div>{option.value}</div>
                                )} */}

                                {practitionerrole?.resource?.identifier?.[0]?.value}
                            </Typography>
                            <Typography sx={{ color: "#816565", fontSize: "14px", textTransform: 'capitalize', display: "flex", alignItems: "center", gap: "2px", }} >
                                <span>
                                    {practitionerrole?.resource?.specialty?.[0]?.coding?.[0]?.display ?
                                        <img
                                            src={StethIcon}
                                            width={20}
                                            height={20}
                                            alt="stethoscope"
                                            sx={{ fontSize: "12px", mr: "5px" }}
                                        /> : null}
                                </span>
                                {practitionerrole?.resource?.specialty?.[0]?.coding?.[0]?.display}
                                {/* General Peadtrics, Neonatolgy */}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container className="text-sm pt-1 font-semibold items-center" justifyContent={"space-between"}>
                    <div className='flex gap-2'>
                        <img src={CommunicationImg} alt='communiction' /> Communication in
                        {userData?.communication?.map((lang) =>
                            <span key={lang?.coding?.[0]?.display} style={{ color: "#051984", fontWeight: 500 }}>
                                {lang?.coding?.[0]?.display}
                            </span>
                        )}
                    </div>
                    <div className='float-right flex gap-5'>
                        <Button
                            variant="outlined"
                            startIcon={<img src={followIcon} width={20} height={20} alt="tele" />}
                            sx={{
                                height: "30px",
                                fontSize: { xs: "14px", lg: "16px" },
                                justifyContent: 'space-between',
                                // padding:'5px',
                                textTransform: "capitalize",
                                borderRadius: "10px",
                                borderColor: "#205072",
                                color: "#205072",
                                "&:hover": { borderColor: "#205072" },
                            }}
                        >
                            Follow
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<ShareOutlined sx={{ fontSize: 12 }} />}
                            sx={{
                                height: "30px",
                                fontSize: { xs: "14px", lg: "16px", },
                                justifyContent: 'space-between',
                                // padding:'5px',
                                textTransform: "capitalize",
                                borderRadius: "10px",
                                borderColor: "#205072",
                                color: "#205072",
                                "&:hover": { borderColor: "#205072" },
                            }}
                            onClick={() => setOpen(true)}
                        >
                            Refer
                        </Button>
                    </div>
                </Grid>
                <hr style={{ marginTop: '10px' }} />
                <Box sx={{ width: '100%' }}>
                    <Tabs value={value} onChange={handleChange} indicatorColor="primary" sx={{ padding: 0, '.MuiTabs-indicator': { bottom: 8 } }}
                        textColor="inherit" aria-label="full width tabs example">
                        <Tab label="Overview" {...a11yProps(0)} sx={{ textTransform: 'capitalize', paddingTop: '0px', paddingBottom: '0px', }} />
                        <Tab label="Medical Services"  {...a11yProps(1)} sx={{ textTransform: 'capitalize', paddingTop: '0px', paddingBottom: '0px', }} />
                        <Tab label="Post & Publications" {...a11yProps(2)} sx={{ textTransform: 'capitalize', paddingTop: '0px', paddingBottom: '0px', }} />
                    </Tabs>
                </Box >

            </Paper>
            {/* <Box sx={{ height: 'calc(100vh - 200px)', mt: 2, overflowY: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}> */}
            <TabPanel value={value} index={0}>
                <Overview userData={userData} doctorData={doctorData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MedicalService userData={userData} practitionerrole={practitionerrole} selectedbranch={selectedbranch} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PostPublications userData={userData} practitionerrole={practitionerrole} selectedbranch={selectedbranch} />
            </TabPanel>
            {/* </Box> */}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <Typography sx={{ p: '15px', backgroundColor: '#E44190', color: 'white' }}>
                        Invite Your Peers
                    </Typography>
                    <CustomRow>
                        <WhatsappShareButton
                            windowWidth='800px'
                            windowHeight='800px'
                            url={url1}
                            title={title}
                            onShareWindowClose={() => handleClose}
                        >
                            <CustomButton name="share Profile" >
                                <WhatsApp className='inside_icon' sx={{ color: 'black' }} />
                            </CustomButton>
                        </WhatsappShareButton>

                        <TwitterShareButton
                            windowWidth='800px'
                            windowHeight='800px'
                            url={url1}
                            title={title}
                        >
                            <CustomButton name="share in Twitter">
                                <Twitter className='inside_icon' sx={{ color: 'black' }} />
                            </CustomButton>
                        </TwitterShareButton>
                    </CustomRow>


                    <CustomRow>
                        <LinkedinShareButton
                            url={url1}
                            title={title}
                        // summary={`${state.about}`}
                        >
                            <CustomButton name="share in Linkedin" >
                                <LinkedIn className='inside_icon' sx={{ color: 'black' }} />
                            </CustomButton>
                        </LinkedinShareButton >
                        <FacebookShareButton
                            windowWidth='800px'
                            windowHeight='800px'
                            // url={url1}
                            // quote={`${state.fullName} - ${user.caption} | Docsteth `}
                            hashtag='#paramitha hospitals'
                        >
                            <CustomButton name="share in Facebook">
                                <Facebook className='inside_icon' sx={{ color: 'black' }}></Facebook>
                            </CustomButton>
                        </FacebookShareButton>
                    </CustomRow >

                    <CustomRow true={true}>
                        <EmailShareButton

                        // url={`https://www.docsteth.com/myprofile/${name}-${id}`}
                        // separator={"    =>  "}
                        // subject={`${state.fullName} - ${user.caption} | Docsteth `}
                        // body={`${state.about}`}
                        >
                            <CustomButton name="Email Invite">
                                <Email className='inside_icon' sx={{
                                    color: 'black', width: '20px',
                                    height: '20px',
                                }} />
                            </CustomButton>
                        </EmailShareButton>
                        {/* <CustomButton name="SMS">
                                                            <SmsIcon className='inside_icon' sx={{ color: 'black' }} />
                                                            </CustomButton> */}
                        <div onClick={HandleCopy}>
                            <CustomButton name="Copy Invite" >
                                <ContentCopy className='inside_icon' sx={{ color: 'black' }} />
                            </CustomButton>
                        </div>
                    </CustomRow>

                    <Typography className="text-center  cursor-pointer" onClick={handleClose} sx={{ mt: 1, p: '10px', backgroundColor: '#FDF3F7', fontWeight: '600', color: '#0f3f5d' }}>
                        Done
                    </Typography>
                </Box >
            </Dialog >

        </div >
    )
}

export default DocProfileHeader