import React, { useEffect, useState } from 'react'
import URL from '../../services/api_url';
import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';


function FrequentlyAsked({ id }) {
    const [faqs, setFaqs] = useState([])
    const [expanded, setExpanded] = useState(null);

    const html = ``;
    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(blocksFromHTML);
    const editorContent = EditorState.createWithContent(content);
    const [editorState, setEditorState] = useState(editorContent);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
        // setEditAns(null);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios(`${URL.feed}feed/knowledBase/healthcare/${id}`);
                let data = res?.data?.map((e, _) => ({
                    ...e,
                    answer: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(e?.answer || `<p></p>`).contentBlocks))
                }));
                setFaqs(data);
            } catch (error) {
                console.error("Error fetching media gallery", error);
            }
        };

        fetchData();


    }, [id])
    return (
        <div>
            <div className='font-semibold text-lg '>Frequently Asked Question</div>
            <div className='mt-2'>
                {faqs?.length > 0 ? faqs?.map((each, i) => (
                    <Accordion
                        key={i}
                        expanded={expanded === i}
                        onChange={handleChange(i)}
                        sx={{ '.MuiButtonBase-root': { padding: '2px 10px !important' } }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0, textTransform: 'capitalize' }}>
                                {each?.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid>

                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        {each?.answer && each?.answer?.getCurrentContent().hasText() ? (
                                            <div
                                                dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(each?.answer.getCurrentContent())) }}
                                                style={{ whiteSpace: 'pre-wrap' }}
                                            />
                                        ) : "write your answer"}
                                    </div>
                                </Grid>

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )) : <div className=' text[16px] pl-6'>No Data Available</div>
                }
            </div>
        </div>
    )
}

export default FrequentlyAsked