import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';

function ImageWithModal({ image, openImage, setOpenImage }) {

    const handleClose = () => setOpenImage(false);

    return (
        <>


            {/* Modal for Fullscreen Image */}
            <Modal
                open={openImage}
                onClose={handleClose}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    // backdropFilter: 'blur(5px)' // Adds a blurred background effect
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Black backdrop with 80% opacity
                    }
                }}
            >
                <Box
                    component="img"
                    src={image}
                    alt="post"
                    sx={{
                        maxHeight: '90vh', // Image height (responsive)
                        maxWidth: '90vw',  // Image width (responsive)
                        objectFit: 'contain', // Ensures the image scales without cutting off
                        borderRadius: '10px',
                        // boxShadow: 24, // Adds shadow around the modal
                        // backdropFilter:'10'
                    }}
                />
            </Modal>
        </>
    );
}

export default ImageWithModal;
