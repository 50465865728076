import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { ArrowForward } from "@mui/icons-material";
import healthserviceicon from '../../../assets/healthcareprograms/Healthcare Service 1.svg';
import { branchInfo } from "../../../redux/slices/branchSlice";
import { selectedBranchInfo } from "../../../redux/slices/SelectedBranchSlice";
import { useDispatch } from "react-redux";

const Img = styled('img')({
  width: '50px',
  height: '54px',
  '@media (max-width: 600px)': {
    width: '35px',
    height: '35px',
  },
});


const SpecialistsTab = ({ branch }) => {
  // console.log(branch, 'branh')

  const dispatch = useDispatch()
  const [specialistInfo, setSpecialistInfo] = React.useState([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const branchId = branch?.id ? branch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`
  useEffect(() => {
    if (branch) {
      const tenant = "parimitha";
      // axios.get(`${process.env.REACT_APP_API_URL_v4}resource/${tenant}/HealthcareService?_count=200&organization=${branch?.id} `,
      setLoading(true)
      axios.get(`${process.env.REACT_APP_API_URL_v4}parimitha/${branchId}/HealthcareService?_count=200`,
        {
          headers: {
            "X-FHIR-TENANT-ID": tenant,
            "Realm": tenant,
            "X-FHIR-DSID": `fhir${tenant} `,
          },
        }
      )
        .then((res) => {
          // let sortList = res.data?.entry ? res.data?.entry.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value) : []
          let sortList = res.data.entry.sort((a, b) =>
            (a.resource.identifier?.find(id => id.system === 'https://positionofservices/')?.value || '999')
              .localeCompare(b.resource.identifier?.find(id => id.system === 'https://positionofservices/')?.value || '999')
          );
          setSpecialistInfo(sortList || []);
        })
        .catch((err) => console.log(err)).finally(() => {
          setLoading(false)
        })
    }
  }, [branch]);

  const handleSelectService = (title, id) => {
    localStorage.setItem("brName", branch?.name);
    localStorage.setItem("selectOrg", branch?.name);

    localStorage.setItem("selectedbranch", JSON.stringify(branch));
    dispatch(branchInfo(branch?.name));
    dispatch(selectedBranchInfo(branch));
    let name = title
    navigate(branch?.name ? `/${branch?.name}/healthcareservices/${name?.replace(/[\s,]+/g, '')}/${id}` : `/healthcareservices/${name?.replace(/[\s,]+/g, '')}/${id}`)

  }
  return (
    <Box
      sx={{ mt: { xs: 1, sm: 1, md: 2 } }}
    >
      <Box sx={{ minHeight: { md: '400px', lg: "420px" } }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <div className="mt-2">
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid container columnSpacing={5} rowSpacing={4} p={{ xs: '0px 0px', sm: '0px 0px', md: "20px 0px" }}>
                {specialistInfo?.length > 0 ?
                  specialistInfo.slice(0, 9).map((val, index) => {
                    const imgSrc = val?.resource?.photo?.url;
                    return (
                      <Grid item xs={12} sm={4} md={4} key={index} >
                        <Box className='flex items-center cursor-pointer px-2 py-2'
                          sx={{
                            display: "flex",
                            cursor: "pointer",
                            opacity: 1,
                            alignItems: 'center',
                            p: "10px 20px",
                            gap: "10px",
                            // backgroundColor: '#FDF3F7',
                            height: '84px',
                            border: '1px solid #E44190',
                            borderRadius: '5px',
                            width: { xs: "100%", md: '100%' },
                            '&:hover': {
                              border: '4px solid #E44190',
                              backgroundColor: '#FDF3F7',

                            },
                          }}
                          onClick={() => handleSelectService(val?.resource?.name, val?.resource?.id)} >
                          <Img
                            src={imgSrc || healthserviceicon}
                            alt="healthspecialities"
                            style={{ marginRight: "8px" }}
                          />
                          <Box className="truncate">
                            <Grid sx={{
                              color: "#1D5775",
                              fontSize: "16px",
                              fontWeight: "500",
                              textTransform: 'capitalize',
                              opacity: "100%",
                            }}>
                              {val?.resource?.name}
                            </Grid>
                            <Grid className="truncate text-sm font-light" sx={{ color: "#525C79" }}>
                              {val?.resource?.comment}
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })
                  :
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                      <Typography variant="h6" sx={{ color: "#525C79" }}>
                        No Specialities available
                      </Typography>
                    </Box>
                  </Grid>}
              </Grid>
            )}
          </div>
        )}
      </Box>
      {specialistInfo && specialistInfo.length >= 9 &&
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", }}>
          <Button
            variant="outlined"
            onClick={() => navigate('healthcareservices')}
            sx={{
              borderColor: "#E54290",
              textTransform: "capitalize",
              color: "#E54290",
              borderRadius: "10px",
              width: { xs: '200px', md: '255px' },
              height: { xs: '44px', md: "54px" },
              '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
            }}
            endIcon={<ArrowForward />}
          >
            <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}>
              Find All Specialities
            </Typography>
          </Button>
        </div>
      }
    </Box>
  );
};

export default SpecialistsTab;
