import { Avatar, Button, CardMedia, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ArticlePost = ({ docFeed }) => {
  const articleData = docFeed.articleData;
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);

  const navigate = useNavigate()
  // console.log(docFeed, "articledata")
  const image = docFeed.sourceLink.replace(/,\s*$/, "");
  const handleViewArticle = () => {
    navigate(selectedbranch?.name ? `/${selectedbranch?.name}/viewarticle/${docFeed.id}`:`/viewarticle/${docFeed.id}`, { state: { docFeed } })
  }
  return (
    <div>
      <div>
        <div
          style={{
            // maxHeight: "300px",
            overflow: "hidden",
            position: "relative",
          }}
          onClick={handleViewArticle}
        >

          {docFeed.sourceLink !== null &&
            docFeed.sourceLink !== "" &&
            docFeed.sourceLink.length > 0 ? (
            <CardMedia
              className="object-fill"
              component="img"
              // height="194"
              sx={{
                maxHeight: { xs: "200px", sm: "200px", md: "240px" },
                //   border: "1px solid red",
              }}
              src={image}
              alt="Paella dish"
            />
          ) : (
            <div>
              <img
                src={
                  "https://media.istockphoto.com/id/1396814518/vector/image-coming-soon-no-photo-no-thumbnail-image-available-vector-illustration.jpg?s=612x612&w=0&k=20&c=hnh2OZgQGhf0b46-J2z7aHbIWwq8HNlSDaNp2wn_iko="
                }
                alt="cover"
                style={{
                  // maxHeight: "300px",
                  //   maxWidth: "100%",
                  //   objectFit: "cover",
                  width: "100%",
                }}
              />

              {/* <div
                style={{ fontFamily: "inherit", padding: "10px" }}
                className="article-para"
                dangerouslySetInnerHTML={{
                  __html: articleData?.articleDescription,
                }}
              /> */}
            </div>
          )}
          <div style={{ position: 'absolute', top: 10, right: 0, padding: '3px 10px', fontSize: '14px', backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: "10px 0px 0px 10px" }}>
            Child Health
          </div>
        </div>
      </div>

      {docFeed?.articleData?.articleTitle !== null ? (
        // <a href={`/viewarticle/${docFeed.id}`}>
        <div
          onClick={handleViewArticle}
          style={{
            padding: "10px 20px",
            //   backgroundColor: "#e8e8e0",
            display: "flex",
            justifyContent: "space-between",
            // marginBottom: "100px",
            // border: "1px solid red",
          }}
          className="d-flex cursor"
        >
          {articleData?.articleTitle ? (
            <p style={{ overflow: "hidden", flex: 1 }}>
              <div
                className="font-bold capitalize add-cover text-ellipsis"
                style={{ pt: 1, fontSize: "20px" }}
              >
                {articleData.articleTitle}
              </div>
            </p>
          ) : null}

          {/* <Button variant="outlined" sx={{ py: "3px!important" }}>
              Read Article
            </Button> */}
        </div>
        // </a>
      ) : null}
    </div>
  );
};

export default ArticlePost;
