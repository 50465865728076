import React, { useRef } from 'react';
import { Box, Grid } from '@mui/material';
import PMJAYIcon from '../../assets/healthcareprograms/pmjay.svg';
import RATGIcon from '../../assets/healthcareprograms/tslogo.svg';
import CGHSIcon from '../../assets/healthcareprograms/cghs_logo.svg';
import NINIcon from '../../assets/healthcareprograms/rohinilogo.svg';
import ERKBIcon from '../../assets/healthcareprograms/dnb.png';
import RHNIIcon from '../../assets/healthcareprograms/rohinilogo.svg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const programData = [
  { img: PMJAYIcon, title: 'Pradan Mantri Jan Arogya Yojana (PM-JAY)' },
  { img: RATGIcon, title: 'Rajiv Aarogyasri Telangana Government' },
  { img: CGHSIcon, title: 'Central Government Health Scheme(CGHS)' },
  { img: NINIcon, title: 'National Institute of Nutrition (NIN)' },
  // { img: ERKBIcon, title: 'E-Rakt Kosh Blood Cell - National Health Mission' },
  { img: ERKBIcon, title: 'D.N.B Neonatology' },
  { img: RHNIIcon, title: 'Registry of Hospitals in Network of Insurance' },
]

const HealthcarePrograms = () => {
  const sliderRef = useRef < Slider > (null);
  const gotoNext = () => {
    sliderRef.current?.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current?.slickPrev();
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          position: 'absolute',
          top: '100%',
          marginTop: '63px',
          // left: '30%',
          left: `calc(50% - 130px)`,
          // marginRight: '5px',
          zIndex: 1,
          cursor: 'pointer',
        }}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          position: 'absolute',
          top: '100%',
          marginTop: '63px',
          // right: '30%',
          right: `calc(50% - 130px)`,
          marginLeft: '10px',
          zIndex: 1,
          cursor: 'pointer',
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    className: "center",
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,

    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    pauseOnHover: true,
    cssEase: "linear",
    prevArrow: <PrevArrow onClick={gotoPrev} />,
    nextArrow: <NextArrow onClick={gotoNext} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <Box className="py-10">
      <div className='font-medium text-xl md:text-3xl lg:text-4xl text-center pt-8' style={{ color: "#E54290" }}>Healthcare Programs</div>
      <div className='font-light text-xl text-center pt-3' style={{ color: "#000000" }}>Find the list of Government health schemes and Programs applicable in our Insurance Network</div>
      <style>
        {`
                      .slider-container .slick-next:before, .slick-prev:before {
                        font-size: 24px;
                        line-height: 1;
                        opacity: 1;
                        color: #E54290 !important;
                      }
                      .slider-container .slick-prev {
                        // margin-top: 369px !important;
                        // padding-left: 37px;
                        z-index: 3;
                      }
                      .slider-container .slick-next {
                        // margin-top: 369px !important;
                        // padding-right: 55px;
                        z-index: 3;
                      }
                      .slider-container .slick-dots li button:before {
                        font-size: 18px;
                        color:#F5C1D9;
                        opacity:1;
                        margin-top: 50px;
                      }
                      .slider-container .slick-dots .slick-active button:before {
                        font-size: 18px;
                        color:#E54290;
                        opacity:1
                      }
                    `}
      </style>
      <Grid className="mt-5 py-5" style={{ background: '#FDF3F7' }}>
        <span className="slider-container">
          <Slider {...settings}>
            {programData.map((val, index) => {
              return (
                <Grid item xs={6} sm={6} key={index} md={4} spacing={5} sx={{ p: "15px" }}>
                  <Box className="text-center pt-5">
                    <div className='flex justify-center' style={{ height: '60px' }}>
                      <img src={val?.img} width={60} height={60} />
                    </div>
                    <div className='pt-3 text-2xl' style={{ color: '#1D5775' }}>{val?.title}</div>

                  </Box>
                </Grid>
              );
            })}
          </Slider>
        </span>
      </Grid>
    </Box>
  )
}

export default HealthcarePrograms