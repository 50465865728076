import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid, useMediaQuery } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { OutlinedButton } from '../atoms/commonbutton';
import ConsultationIcon from '../../assets/healthportal-icons/consultations.svg';
import { getAppointments } from '../../redux/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../localstore/localstore';
import moment from 'moment';
import BranchUrl from '../../services/api_branch_url';
import VisitClinic from './visitClinic';

function Consultations() {
    const isXsScreen = useMediaQuery("(max-width:900px)");
    const usertoken = localstore.getToken();
    const pId = localstore.getPatientIbmId()
    const ibmId = localstore.getIbmId();
    const [appointmentDetails, setAppointmentDetails] = useState('')
    const dispatch = useDispatch();
    const urls = BranchUrl();
    const patientAppointments = useSelector((state) => state.paramitha.patientAppointments);
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    const [openClinic, setOpenClinic] = useState(false)
    useEffect(() => {
        if (usertoken && ibmId) {
            dispatch(getAppointments(urls, usertoken, ibmId));
        }
    }, [usertoken, ibmId]);

    const now = moment();

    const upcomingAppointments = patientAppointments?.filter(appointment =>
        moment(appointment?.appointment?.start).isAfter(now)
    ) || [];

    const pastAppointments = patientAppointments?.filter(appointment =>
        moment(appointment?.appointment?.start).isBefore(now)
    ) || [];
    const handleClickClinc = (value) => {
        setAppointmentDetails(value)
        setOpenClinic(true)
    }
    return (
        <div>
            <Box>
                {!isXsScreen &&
                    <Typography color={'#155775'} className='font-medium text-sm fntwt-600 flex align-item' gap={"10px"}>
                        <img src={ConsultationIcon} alt="consult" /> Consultations
                    </Typography>
                }
                <Paper elevation={3} sx={{ p: "10px 15px", mt: "10px", borderRadius: "5px" }}>
                    <Typography className='fntwt-600'>Upcoming Appointments</Typography>
                    <Box p={"5px 0px"}>
                        {upcomingAppointments.length > 0 ? (
                            upcomingAppointments.map((doctor, index) => {
                                const appointmentStatus = doctor?.appointment?.status
                                return (
                                    <>
                                        <Grid container sx={{ mt: '15px' }} key={index}>
                                            <Grid item md={6} sm={6} pr={"10px"}>
                                                <Typography className='fntsz-14 fntwt-500 capitalize'>{doctor?.appointment?.participant?.[1]?.actor?.display}</Typography>
                                                <Typography className='fntsz-13' mt="3px">{doctor?.PractitionerRole?.specialty?.[0]?.coding?.[0]?.display || ''}</Typography>
                                                <div className='flex items-center text-xs'><span style={{ backgroundColor: '#F1FAFF', padding: '2px' }}><LocationOn sx={{ fontSize: 13 }} /> Paramita Children's Hospital, {selectedbranch?.name}</span></div>
                                            </Grid>
                                            <Grid item md={3} sm={3}>
                                                <Box className="mt-2"></Box>
                                                <Typography className='fntsz-14 capitalize'>{doctor?.appointment?.appointmentType?.coding?.[0]?.code?.toLowerCase()}</Typography>
                                                <Typography className='fntsz-13' mt="3px">Due {moment(doctor?.appointment?.end).format('DD-MM-YYYY')}</Typography>
                                            </Grid>
                                            <Grid item md={3} sm={3}>
                                                <Box className="mt-2"></Box>
                                                <OutlinedButton text={appointmentStatus === "booked" ? "Visit Clinc" : "Schedule Visit"} borderColor="#ED70AC" borderRadius="10px" width="140px" fontSize="14px" height="32px" onClick={() => appointmentStatus === "booked" ? handleClickClinc(doctor) : ''} />
                                            </Grid>
                                        </Grid>
                                    </>

                                )
                            })
                        ) : (
                            <Typography className='text-medium mt-1 text-center'>No upcoming appointments</Typography>
                        )}
                    </Box>
                </Paper>

                <Paper elevation={3} sx={{ p: "10px 15px", mt: "20px", borderRadius: "5px" }}>
                    <Typography className='fntwt-600'>Consultation History</Typography>
                    <Box p={"5px 0px"}>
                        {pastAppointments.length > 0 ? (
                            pastAppointments.map((doctor, index) => (
                                <Grid container sx={{ mt: '15px' }} key={index}>
                                    <Grid item md={6} sm={6} pr={"10px"}>
                                        <Typography className='fntsz-14 fntwt-500 capitalize'>{doctor?.appointment?.participant?.[1]?.actor?.display}</Typography>
                                        <Typography className='fntsz-13' mt="3px">{doctor?.PractitionerRole?.specialty?.[0]?.coding?.[0]?.display || ''}</Typography>
                                        <div className='flex items-center text-xs'><span style={{ backgroundColor: '#F1FAFF', padding: '2px' }}><LocationOn sx={{ fontSize: 13 }} /> Paramita Children's Hospital, {selectedbranch?.name}</span></div>

                                    </Grid>
                                    <Grid item md={3} sm={3}>
                                        <Box className="mt-2"></Box>
                                        <Typography className='fntsz-14 capitalize'>{doctor?.appointment?.appointmentType?.coding?.[0]?.code?.toLowerCase()}</Typography>
                                        <Typography className='fntsz-13' mt="3px">Due {moment(doctor?.appointment?.end).format('DD-MM-YYYY')}</Typography>
                                    </Grid>
                                    <Grid item md={3} sm={3}>
                                        <Box className="mt-2"></Box>
                                        <OutlinedButton text="View Summary" borderColor="#ED70AC" borderRadius="10px" disabled width="140px" fontSize="14px" height="32px" />
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <Typography className='text-medium mt-1 text-center'>No consultation history</Typography>
                        )}
                    </Box>
                </Paper>
                <VisitClinic open={openClinic} setOpenClinic={setOpenClinic} appointmentDetails={appointmentDetails} />

            </Box >
        </div >
    );
}

export default Consultations;
