import React, { useEffect, useState } from 'react';
import { Button, Card, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

function DepartmentOverview({ locationInfo }) {
    const [editAboutText, setEditAboutText] = useState('');
    const [about, setAbout] = useState(false);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (locationInfo?.resource?.description) {
            if (!about) {
                const filteredData = locationInfo?.resource?.description?.length > 400
                    ? locationInfo?.resource?.description?.slice(0, 400)
                    : locationInfo?.resource?.description;
                setEditAboutText(filteredData);
            } else {
                setEditAboutText(locationInfo?.resource?.description);
            }
        } else {
            setEditAboutText('No data available');
        }
    }, [locationInfo, about]);

    const handleToggleAbout = () => {
        setAbout(prevAbout => !prevAbout);
    };

    return (
        <div>
            <div className='font-semibold text-lg'>About</div>

            <div>
                <Typography my={1}>
                <div
                    dangerouslySetInnerHTML={{ __html: editAboutText }}
                    // style={{ whiteSpace: 'pre-wrap' }}
                    style=
                    {{
                      display: !about ? '-webkit-box' : 'block',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 2, // Set this to the number of lines you want to display
                      whiteSpace: 'pre-wrap'
                    }}

                  />
                </Typography>
                {locationInfo?.resource?.description?.length > 400 && (
                    <div className='text-center mt-2' onClick={handleToggleAbout}
                    >
                        {about ? (
                            <Button
                                variant="outlined"

                                sx={{
                                    borderColor: "#E54290",
                                    textTransform: "capitalize",
                                    color: "#E54290",
                                    borderRadius: "10px",
                                    width: "150px",
                                    '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                                }}
                                endIcon={<ExpandLess />}
                            >
                                <Typography sx={{ fontSize: { xs: '14px', md: "16px", fontWeight: 500 } }}>Show Less</Typography>
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"

                                sx={{
                                    borderColor: "#E54290",
                                    textTransform: "capitalize",
                                    color: "#E54290",
                                    borderRadius: "10px",
                                    width: "150px",

                                    '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                                }}
                                endIcon={<ExpandMore />}
                            >
                                <Typography sx={{ fontSize: { xs: '14px', md: "16px", fontWeight: 500 } }}>Show More</Typography>
                            </Button>
                        )}

                    </div>

                )}
                {/* <Card>
                    {locationInfo?.resource?.description?.length > 400 && (
                        <div
                            style={{ padding: '8px', cursor: 'pointer', fontWeight: 400, background: hover ? "#FDF3F7" : "", color: hover ? "#E44190" : '#E44190' }}
                            onClick={handleToggleAbout}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            {about ? (
                                <div className='flex justify-center items-center'>
                                    <div> Show less </div>
                                    <ExpandLess />
                                </div>
                            ) : (
                                <div className='flex justify-center items-center'>
                                    <div> Show More </div>
                                    <ExpandMore />
                                </div>
                            )}
                        </div>
                    )}
                </Card> */}
            </div>
        </div>
    );
}

export default DepartmentOverview;
