import React from 'react'
import CommonDialog from './commonModal'
import { Button, DialogContent, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function FeedAlert({ open, setOpen }) {
    const navigate = useNavigate()
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    return (

        <CommonDialog open={open}  >
            <DialogContent sx={{ p: '10px 30px 10px 30px' }}>
                Your are unable to access the data, please log in or sign up for an account
                <Divider sx={{ mt: 1 }} />
                <div className='flex items-center justify-between p-1'>
                    <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => setOpen(false)}>Cancel</Button>
                    <div > <span className='text-[#1B5775] cursor-pointer hover:underline' onClick={() => navigate(
                        selectedbranch?.name ? `/${selectedbranch?.name}/signup` : "/signup"
                    )}>Signup </span><span>/</span> <span className='text-[#1B5775] cursor-pointer hover:underline' onClick={() => navigate(
                        selectedbranch?.name ? `/${selectedbranch?.name}/login` : "/login"
                    )}>Login</span></div>
                </div>
            </DialogContent>

        </CommonDialog>

    )
}

export default FeedAlert