import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    XIcon,
    EmailIcon,
} from 'react-share';

export const SocialShare = ({ url, size }) => {
    console.log(url);

    const handleEmailOnClick = (service) => {
        console.log(url)
        const subject = 'Have a look at this Post';
        const body = `Have a look at this Post!\n\n${url}`;

        let mailtoLink = '';
        if (service === 'gmail') {
            mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${encodeURIComponent(
                subject,
            )}&body=${encodeURIComponent(body)}`;
        } else if (service === 'outlook') {
            mailtoLink = `mailto:?subject=${encodeURIComponent(
                subject,
            )}&body=${encodeURIComponent(body)}`;
        }

        window.location.href = mailtoLink;
    };

    const handleOutlookOnClick = () => {
        const subject = 'Have a look at this Post';
        const body = `Have a look at this Post!\n\n${url}`;
        window.location.href = `mailto:?subject=${encodeURIComponent(
            subject,
        )}&body=${encodeURIComponent(body)}`;
    };



    const message = 'Have a look at this Post!';

    return (
        <>
            <FacebookShareButton
                url={url}
            // quote={"some"}
            // hashtag='#url'

            >
                <FacebookIcon size={34} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={message}>
                <XIcon size={34} round={true} />
            </TwitterShareButton>
            <div
                onClick={() => handleEmailOnClick('gmail')}
                style={{ cursor: 'pointer' }}
            >
                {/* <EmailIcon size={34} round={true} /> */}
                <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="17" cy="17" r="17" fill="#D44638" />
                    <g clipPath="url(#clip0_1724_29206)">
                        <path
                            d="M24.3125 10.25H9.6875C9.23995 10.25 8.81072 10.4278 8.49426 10.7443C8.17779 11.0607 8 11.4899 8 11.9375V22.0625C8 22.5101 8.17779 22.9393 8.49426 23.2557C8.81072 23.5722 9.23995 23.75 9.6875 23.75H24.3125C24.7601 23.75 25.1893 23.5722 25.5057 23.2557C25.8222 22.9393 26 22.5101 26 22.0625V11.9375C26 11.4899 25.8222 11.0607 25.5057 10.7443C25.1893 10.4278 24.7601 10.25 24.3125 10.25ZM22.3258 11.375L17 15.5802L11.6743 11.375H22.3258ZM10.25 22.625V13.2267L17 18.4186L23.75 13.2256V22.625H10.25Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1724_29206">
                            <rect width="18" height="18" fill="white" transform="translate(8 8)" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
            <div
                onClick={() => handleEmailOnClick('outlook')}
                style={{ cursor: 'pointer' }}
            >
                <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="17" cy="17" r="17" fill="#7D7D7D" />
                    <path
                        d="M23.875 10.125H10.125C9.62772 10.125 9.15081 10.3225 8.79917 10.6742C8.44754 11.0258 8.25 11.5027 8.25 12V12.25L16.775 16.8312C16.8521 16.8639 16.935 16.8807 17.0188 16.8807C17.1025 16.8807 17.1854 16.8639 17.2625 16.8312L25.75 12.25V12C25.75 11.5027 25.5525 11.0258 25.2008 10.6742C24.8492 10.3225 24.3723 10.125 23.875 10.125ZM25.55 22.825C25.6789 22.5689 25.7473 22.2867 25.75 22V13.6688L20.0625 16.725L25.55 22.825ZM8.25 13.6688V22C8.25272 22.2867 8.32114 22.5689 8.45 22.825L13.9375 16.725L8.25 13.6688ZM17.8187 17.9312C17.5586 18.0535 17.2749 18.1175 16.9875 18.1188C16.7201 18.1184 16.4558 18.0609 16.2125 17.95L15.0687 17.325L9.35 23.7C9.59287 23.813 9.85713 23.8727 10.125 23.875H23.875C24.145 23.8736 24.4115 23.8139 24.6562 23.7L18.925 17.3375L17.8187 17.9312Z"
                        fill="white"
                    />
                </svg>
                {/* <Outlook size={34} round={true} /> */}
            </div>
            {/* <FacebookMessengerShareButton url={url} appId="">
          <MessangerIcon />
        </FacebookMessengerShareButton> */}
            <WhatsappShareButton url={url} title={message}>
                <WhatsappIcon size={34} round={true} />
            </WhatsappShareButton>
        </>
    );
};
