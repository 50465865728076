import Keycloak from "keycloak-js";

// const keycloakRealm = localStorage.getItem('brName');

const keycloak = new Keycloak({

  realm: 'parimitha',
  clientId: "paramithaWeb",

  url: process.env.REACT_APP_KEYCLOAK
  //* development*
  // url: "https://keycloak.docsteth.com",
  // url: "https://devsecure.docsteth.com",

  //*Production*
  // url: "https://secure.docsteth.com",


});

export default keycloak;