import React from 'react'
import { Box, Button, Dialog, Divider, Typography } from '@mui/material';
import { WhatsappShareButton, EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import { ContentCopy, Email, Facebook, LinkedIn, ShareOutlined, Twitter, WhatsApp } from '@mui/icons-material';


const CustomButton = (props) => {
    return (
        <Button className="add-option" sx={{ mt: '0px!important', mr: 2, fontWeight: '550!important', padding: '5px 12px!important', color: '#E44190' }} variant="outlined">{props.children}&nbsp;{props.name}</Button>
    )
}


const CustomRow = (props) => {
    return (
        <Typography component="div" sx={{ px: 2, pt: 2 }} >
            <Typography component="p" sx={{ pb: 1 }} className="flex items-center">
                {props.children}
            </Typography>
            {props.true ? '' : <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />}
        </Typography>
    )
}

function SocialMediaShare({ open, handleClose, url, title, }) {
    const handleCopy = () => {
        navigator.clipboard.writeText(url)

    }
    return (

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box>
                <Typography sx={{ p: '15px', backgroundColor: '#E44190', color: 'white' }}>
                    Invite Your Peers
                </Typography>
                <CustomRow>
                    <WhatsappShareButton
                        windowWidth='800px'
                        windowHeight='800px'
                        url={url}
                        title={title}
                        onShareWindowClose={() => handleClose}
                    >
                        <CustomButton name="share Profile" >
                            <WhatsApp className='inside_icon' sx={{ color: 'black' }} />
                        </CustomButton>
                    </WhatsappShareButton>

                    <TwitterShareButton
                        windowWidth='800px'
                        windowHeight='800px'
                        url={url}
                        title={title}
                    >
                        <CustomButton name="share in Twitter">
                            <Twitter className='inside_icon' sx={{ color: 'black' }} />
                        </CustomButton>
                    </TwitterShareButton>
                </CustomRow>


                <CustomRow>
                    <LinkedinShareButton
                        url={url}
                        title={title}
                    // summary={`${state.about}`}
                    >
                        <CustomButton name="share in Linkedin" >
                            <LinkedIn className='inside_icon' sx={{ color: 'black' }} />
                        </CustomButton>
                    </LinkedinShareButton >
                    <FacebookShareButton
                        windowWidth='800px'
                        windowHeight='800px'
                        // url={url1}
                        // quote={`${state.fullName} - ${user.caption} | Docsteth `}
                        hashtag='#paramitha hospitals'
                    >
                        <CustomButton name="share in Facebook">
                            <Facebook className='inside_icon' sx={{ color: 'black' }}></Facebook>
                        </CustomButton>
                    </FacebookShareButton>
                </CustomRow >

                <CustomRow true={true}>
                    <EmailShareButton

                    // url={`https://www.docsteth.com/myprofile/${name}-${id}`}
                    // separator={"    =>  "}
                    // subject={`${state.fullName} - ${user.caption} | Docsteth `}
                    // body={`${state.about}`}
                    >
                        <CustomButton name="Email Invite">
                            <Email className='inside_icon' sx={{
                                color: 'black', width: '20px',
                                height: '20px',
                            }} />
                        </CustomButton>
                    </EmailShareButton>
                    {/* <CustomButton name="SMS">
                                                    <SmsIcon className='inside_icon' sx={{ color: 'black' }} />
                                                    </CustomButton> */}
                    <div onClick={handleCopy}>
                        <CustomButton name="Copy Invite" >
                            <ContentCopy className='inside_icon' sx={{ color: 'black' }} />
                        </CustomButton>
                    </div>
                </CustomRow>

                <Typography className="text-center  cursor-pointer" onClick={handleClose} sx={{ mt: 1, p: '10px', backgroundColor: '#FDF3F7', fontWeight: '600', color: '#0f3f5d' }}>
                    Done
                </Typography>
            </Box >
        </Dialog >
    )
}

export default SocialMediaShare;