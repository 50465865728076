import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import PaybillIcon from '../../assets/healthportal-icons/payyourbillonline.svg'


function PaybillOnline() {
    const isXsScreen = useMediaQuery("(max-width:900px)");
    return (
        <Box>
            {!isXsScreen &&
                <Typography color={'#155775'} className='font-medium text-sm fntwt-600 flex align-item' gap={"10px"}><img src={PaybillIcon} alt="medical" /> Medical Records</Typography>
            }

            <div className='p-10 text-center'>
                Coming Soon
            </div>
        </Box>
    )
}

export default PaybillOnline