import React, { useEffect, useRef, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Typography, useMediaQuery } from '@mui/material'
import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { CustomTextField } from '../atoms/TextField'
import { OutlinedButton } from '../atoms/commonbutton'
import * as yup from 'yup';
import URL from '../../services/api_url';
import axios from 'axios';
import { Formik } from 'formik';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import LoginBgImg from '../../assets/LOGIN PAGE IMAGE.svg';
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSelector } from 'react-redux'


const Forgotpass = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState(null)
    const [mobile, setMobile] = useState('')

    const [email, setEmail] = useState(null)
    const [sendOtp, setSendOtp] = useState(false)
    const [showusername, setShowUsername] = useState(false)
    const [userError, setUserError] = useState(null)
    const [otp, setOtp] = useState(null)
    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [loading, setLoading] = useState(false)
    const formikRef = useRef(null);  // Create a ref to store formik's resetForm function
    const ismobile = useMediaQuery('(min-width:900px)');
    const [captchToken, setCaptchToken] = useState(null)
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)


    const signUpValidationSchema = yup.object().shape({
        createpassword: yup
            .string()
            .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
            .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
            .matches(/\d/, 'Password must have a number')
            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .required('Password is required'),

        confirmPassword: yup
            .string()
            .oneOf([yup.ref('createpassword')], 'Passwords do not match')
            .required('Confirm password is required'),

    })


    const handleClickShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword)
    }
    const handleClickShowConPassword = () => {
        setShowConPassword((prevShowPassword) => !prevShowPassword)
    }



    const handleChangeUsername = (e, setFieldTouched, setFieldValue, setFieldError) => {
        const { value } = e.target;
        setUsername(value)
        setUserError('')
        setAlertmessage('')
        // if (value) {
        //     debouncedCheckUsername(value);
        // } else {
        //     setUserError('');
        // }
    };

    const handleRecaptchaChange = (value) => {
        setCaptchToken(value)
        setAlertmessage('')
    }

    const handleSendOtp = () => {
        // setSendOtp(true)

        const data1 = {
            "userName": username,
        };
        const data2 = {
            "realm": 'parimitha',
            "messagetype": "OTP",
            "apptype": "paramitha",
            "username": username,
            "subject": "FORGOT PASSWORD"
        }
        axios.post(`${URL.check}/findPatientsByUserNameViaRealm/parimitha`, data1, { headers: { 'realm': 'parimitha' } })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    if (captchToken) {
                        setLoading(true)

                        axios.post(`${URL.otp}forgotpassword`, data2, {
                            headers: {
                                'Authorization': `Bearer ${captchToken}`
                            }
                        }).then(res => {
                            if (res.status === 200 || res.status === 201) {
                                setAlertmessage('OTP send successfully')
                                setAlertstatus('success')
                                setSendOtp(true)

                            }
                        }).catch((error) => {
                            const errorMessage = error.response ? error.response.data.msg : "An error occurred while sending OTP.";

                            setAlertmessage(errorMessage)
                            setAlertstatus('error')
                        }).finally(() => {
                            setLoading(false)
                        })
                    } else {
                        setAlertmessage('Please verify the reCAPTCHA!')
                        setAlertstatus('error')
                    }

                }

            }).catch(error => {
                setUserError(error.response.data);
            });
    }

    const handleVerifyOtp = async () => {
        // setShowUsername(true)

        const data = {
            "paramitha": "True",
            "phoneNumber": mobile,
            "otp": otp,
            "email": "",
            "realm": 'parimitha',

        }
        setLoading(true)
        await axios.post(`${URL.otp}verifyOtpparamitha?verify=True`, data).then(res => {
            if (res.status === 200 || res.status === 201) {
                setAlertmessage('OTP verified successfully')
                setAlertstatus('success')
                setShowUsername(true)
            }

        }).catch((err) => {
            setAlertmessage(err.message)
            setAlertstatus('error')
        }).finally(() => {
            setLoading(false)
        })

    }





    const handleDonePage = () => {
        setAlertmessage('')
        // setForgotuserOpen(false)
        navigate(selectedbranch?.name ? `/${selectedbranch?.name}/login` : '/login')
        if (formikRef.current) {
            formikRef.current.resetForm();  // Reset the form when the dialog is closed
        }
    }

    return (
        <div style={{ backgroundColor: "#EC7AB3", }}>
            <Grid container>
                <Grid item xs={12} sm={12} md={7}>
                    <Box sx={{
                        padding: { xs: "20px", sm: "20px 40px", md: "20px 40px" },
                        // backgroundColor: "#1B5775", // Assuming you want a background color for the left section
                    }}>
                        <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 20 }, color: "white", textTransform: "capitalize" }}>
                            Manage Your Appointments, Prescriptions <br />Medical Records, Test Reports & More
                        </Typography>
                        {ismobile && (
                            <Box mt={2}>
                                <img src={LoginBgImg} alt="login" style={{ width: '100%' }} />
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <Box sx={{
                        padding: { xs: "20px 10px", sm: "20px 40px", md: "20px 40px" },
                    }}>

                        <Paper elevation={3} sx={{ padding: { xs: "20px 20px", sm: '20px 20px', md: '20px 20px', lg: '20px 40px' } }}>

                            {!showusername ?
                                <>
                                    <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 18 }, textTransform: "capitalize", color: "#155775", fontWeight: 600 }}>
                                        Forgot Password
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <Box sx={{ mt: 2 }}>
                                                <Box sx={{ mb: 1 }}>
                                                    <label style={{ fontWeight: 500 }}>User Name</label>
                                                </Box>
                                                <CustomTextField placeholder="Enter User Name" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={username} disabled={sendOtp} onChange={handleChangeUsername} text="@ Paramitha" />

                                                {userError && <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                    {userError}</Typography>}
                                            </Box>
                                            {sendOtp &&
                                                < div className='mt-1'>
                                                    <Box>
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Verify OTP</label>
                                                    </Box>
                                                    <CustomTextField placeholder="Enter OTP " type={'text'} borderColor="#59D8FA" borderRadius="7px" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                                </div>
                                            }
                                            {!sendOtp &&
                                                <Box sx={{ mt: 4, pl: "15px", transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                                    <ReCAPTCHA
                                                        sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                                        onChange={handleRecaptchaChange}
                                                        size="normal"
                                                    // style={{ transform: 'scale(0.9)' }}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                    <div className='text-center'> <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                                    <div className='mt-3 flex justify-end'>
                                        <OutlinedButton text={sendOtp ? "Verify Otp" : "Send Otp"} borderColor="#1B5775" width="150px" height="33px" fontSize="14px" color="#1B5775" disabled={sendOtp ? !otp : !username} borderRadius="7px" onClick={sendOtp ? handleVerifyOtp : handleSendOtp} endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} />
                                    </div>
                                </> : <Formik
                                    validationSchema={signUpValidationSchema}
                                    enableReinitialize={true}
                                    initialValues={{ createpassword: "", confirmPassword: "" }}
                                    onSubmit={async (values) => {
                                        const data = {
                                            userName: username,
                                            password: values.createpassword
                                        }
                                        axios.put(`${URL.check}/resetPassword/parimitha`, data, {
                                            headers: {
                                                'realm': 'parimitha'
                                            }
                                        })
                                            .then(res => {
                                                if (res.status === 200 || res.status === 201) {
                                                    alert('Your password has been successfully changed.')
                                                    handleDonePage()
                                                }
                                            })
                                    }}
                                >
                                    {
                                        ({
                                            handleSubmit,
                                            isValid,
                                            values,
                                            errors,
                                            handleChange,
                                            handleBlur,
                                            touched,
                                            isSubmitting,
                                            setFieldValue,
                                            setFieldError,
                                            setFieldTouched
                                        }) => (
                                            <>
                                                <div >
                                                    <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 18 }, textTransform: "capitalize", color: "#155775", fontWeight: 600 }}>
                                                        Reset Password
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <div className='mt-1'>
                                                                <Box>
                                                                    <label style={{ fontWeight: 500, fontSize: '14px' }}> New Password</label>
                                                                </Box>
                                                                <CustomTextField
                                                                    // label="Display name"
                                                                    placeholder="New Password"
                                                                    borderRadius="7px"
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    borderColor="#59D8FA"
                                                                    icon={showPassword ? <VisibilityOff /> : <Visibility />}

                                                                    value={values.createpassword}

                                                                    onChange={handleChange('createpassword')}
                                                                    handleBlur={handleBlur('createpassword')}
                                                                    onClick={handleClickShowPassword}
                                                                    error={touched.createpassword && Boolean(errors.createpassword)}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleSubmit();
                                                                        }
                                                                    }}
                                                                />
                                                                {errors.createpassword && touched.createpassword && (
                                                                    <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                                        {touched.createpassword && errors.createpassword}
                                                                    </Typography>
                                                                )}
                                                            </div>
                                                            <div className='mt-1'>
                                                                <Box>
                                                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Confirm Password</label>
                                                                </Box>
                                                                <CustomTextField
                                                                    // label="Display name"
                                                                    placeholder="Re-enter your Password"

                                                                    type={showConPassword ? 'text' : 'password'}
                                                                    borderColor="#59D8FA"
                                                                    icon={showConPassword ? <VisibilityOff /> : <Visibility />}
                                                                    borderRadius="7px"
                                                                    value={values.confirmPassword}

                                                                    onChange={handleChange('confirmPassword')}
                                                                    handleBlur={handleBlur('confirmPassword')}
                                                                    onClick={handleClickShowConPassword}
                                                                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}

                                                                />
                                                                {errors.confirmPassword && touched.confirmPassword && (
                                                                    <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                                        {touched.confirmPassword && errors.confirmPassword}
                                                                    </Typography>
                                                                )}
                                                            </div>

                                                        </Grid>

                                                    </Grid>
                                                </div>

                                                <div className='text-center mt-1'> <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                                                <div className='mt-3 flex justify-end'>
                                                    {/* <OutlinedButton text="Done" borderColor="#1B5775" height="33px" fontSize="14px" color="#1B5775" borderRadius="7px" onClick={handleSubmit} /> */}
                                                    <OutlinedButton text={"Done"} borderColor="#1B5775" width="120px" height="33px" fontSize="14px" color="#1B5775" borderRadius="7px" onClick={handleSubmit} endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} />

                                                </div>

                                            </>
                                        )}
                                </Formik>
                            }
                        </Paper>
                    </Box>
                </Grid>

            </Grid >


        </div >
    )
}

export default Forgotpass