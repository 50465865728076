import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import MovingIcon from "@mui/icons-material/Moving";

const SearchDiv = styled("div")(({ theme }) => ({
  position: "relative",
  //   borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  borderBottom: "1px solid blue",
  [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(3),
    width: "600px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // width: "20ch",
      width: "600px",
    },
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Search = () => {
  const searchedValues = [
    "Woman Health",
    "Pregnancy Care",
    "New Born Care",
    "Lactation",
    "Pediatric's and Child Health",
    "Adollescents Health",
  ];
  return (
    <Box
      sx={{
        mt: 1,
      }}
    >
      <SearchDiv>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search to conditions, topics, medication"
          inputProps={{ "aria-label": "search" }}
        />
      </SearchDiv>
      <div className="flex items-center mt-1 font-medium">
        <MovingIcon sx={{ m: "0px 20px 0px 10px" }} />
        Trending Topics
      </div>

      <Box sx={{ mt: "10px", pointerEvents: 'auto', opacity: 0.2 }}>
        {searchedValues.map((val, index) => (
          <Button
            variant="outlined"
            key={index}
            sx={{
              color: "#707070",
              borderRadius: "20px",
              p: "5px 15px",
              m: "5px 15px",
              border: "1px solid #BEBEBE ",
              textTransform: "capitalize",
            }}
          >
            {val}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default Search;
