import React from 'react'

function FamilyHistory() {
    return (
        <div className='p-2 text-center'>
            Coming Soon
        </div>
    )
}

export default FamilyHistory