import React, { useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material'
import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { CustomTextField } from '../atoms/TextField'
import { OutlinedButton } from '../atoms/commonbutton'
import { useSelector } from 'react-redux'
import URL from '../../services/api_url'
import axios from 'axios'
import { Formik } from 'formik'
import * as yup from 'yup';
import { debounce } from 'lodash'


function ForgotPassword({ open, setForgotpassOpen }) {
    const [username, setUsername] = useState(null)
    const [mobile, setMobile] = useState('')
    const [otp, setOtp] = useState(null)
    const [userError, setUserError] = useState(null)
    const [sendOtp, setSendOtp] = useState(false)
    const [showusername, setShowUsername] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [loading, setLoading] = useState(false)
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
    const signUpValidationSchema = yup.object().shape({
        createpassword: yup
            .string()
            .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
            .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
            .matches(/\d/, 'Password must have a number')
            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .required('Password is required'),

        confirmPassword: yup
            .string()
            .oneOf([yup.ref('createpassword')], 'Passwords do not match')
            .required('Confirm password is required'),

    })

    const handleSendOtp = () => {
        const data1 = {
            "userName": username,
        };
        const data2 = {
            "realm": 'parimitha',
            "messagetype": "OTP",
            "apptype": "paramitha",
            "username": username,
            "subject": "FORGOT PASSWORD"
        }
        axios.post(`${URL.check}/findPatientsByUserNameViaRealm/parimitha`, data1, { headers: { 'realm': 'parimitha' } })
            .then(res => {
                // timeinterval();
                if (res.status === 200 || res.status === 201) {

                    setLoading(true)
                    window.grecaptcha.ready(_ => {
                        window.grecaptcha
                            .execute("6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-", { action: "submit" })
                            .then(token => {
                                // console.log(token, "pythontokennnn")
                                axios.post(`${URL.otp}forgotpassword`, data2, {
                                    headers: {
                                        "Authorization": `Bearer ${token}`,
                                    }
                                }).then(res => {
                                    if (res.status === 200 || res.status === 201) {
                                        setAlertmessage(res.data.msg)
                                        setAlertstatus('success')
                                        setSendOtp(true)
                                        setMobile(res.data.phoneNumber)
                                    }

                                }).catch((error) => {

                                    setAlertmessage(error.message)
                                    setAlertstatus('error')
                                }).finally(() => {
                                    setLoading(false)
                                })
                            })
                    })
                }
            })
            .catch(error => {
                setUserError(error.response.data);
            });



        // setSendOtp(true)
        // window.location.href = `${process.env.REACT_APP_KEYCLOAK}/realms/${selectedbranch.tenant}/login-actions/reset-credentials?client_id=fhirFE`
    }
    const debouncedCheckUsername = debounce((value, setFieldError) => {
        const data = {
            "userName": value,
        };
        axios.post(`${URL.check}/findPatientsByUserNameViaRealm/parimitha`, data, { headers: { 'realm': 'parimitha' } })
            .then(res => {
                // setUserRes(res.data)
            })
            .catch(error => {
                setUserError(error.response.data);
            });
    }, 500); // 300ms de
    const handleChangeUsername = (e, setFieldTouched, setFieldValue, setFieldError) => {
        const { value } = e.target;
        setUsername(value)
        setUserError('')
        setAlertmessage('')
        // if (value) {
        //     debouncedCheckUsername(value);
        // } else {
        //     setUserError('');
        // }
    };
    const handleVerifyOtp = async () => {
        const data = {
            "paramitha": "True",
            "phoneNumber": mobile,
            "otp": otp,
            "email": "",
            "realm": 'parimitha',

        }
        setLoading(true)
        await axios.post(`${URL.otp}verifyOtpparamitha?verify=True`, data).then(res => {
            if (res.status === 200 || res.status === 201) {
                setAlertmessage('otp verified successfully')
                setAlertstatus('success')
                setShowUsername(true)
            }

        }).catch((err) => {
            setAlertmessage(err.message)
            setAlertstatus('error')
        }).finally(() => {
            setLoading(false)
        })

    }
    const handleClickShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword)
    }
    const handleClickShowConPassword = () => {
        setShowConPassword((prevShowPassword) => !prevShowPassword)
    }
    const handleDonePage = () => {
        setForgotpassOpen(false)
        setShowUsername(false)
        setSendOtp(false)
        setUserError('')
        setUsername('')
        setOtp('')
    }

    return (
        <div>
            <CommonDialog open={open} maxWidth="sm" onClose={() => setForgotpassOpen(false)} sx={{ '.MuiDialog-paper': { top: { xs: 0, sm: 0, md: -90 } } }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Forgot Password</Typography>
                    <IconButton onClick={handleDonePage}><Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle>
                <Divider />
                {!showusername ?
                    <>
                        <DialogContent>
                            <div className='px-2'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12}>

                                        <div >
                                            <Box>
                                                <label style={{ fontWeight: 500, fontSize: '14px' }}>User Name</label>
                                            </Box>
                                            <CustomTextField placeholder="Enter User Name" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={username} disabled={sendOtp} onChange={handleChangeUsername} text="@ Paramitha" />
                                            {userError && <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                {userError}</Typography>}
                                        </div>

                                        {sendOtp &&
                                            < div className='mt-1'>
                                                <Box>
                                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Verify OTP</label>
                                                </Box>
                                                <CustomTextField placeholder="Enter OTP " type={'text'} borderColor="#59D8FA" borderRadius="7px" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                            </div>
                                        }


                                    </Grid>

                                </Grid>
                            </div>

                        </DialogContent >
                        <Divider />

                        <DialogActions sx={{ m: "5px 20px" }}>

                            <div>
                                <OutlinedButton text={sendOtp ? "Verify Otp" : "Send Otp"} borderColor="#1B5775" width="120px" height="33px" fontSize="14px" color="#1B5775" disabled={sendOtp ? !otp : !username} borderRadius="7px" onClick={sendOtp ? handleVerifyOtp : handleSendOtp} endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} />
                            </div>
                        </DialogActions>
                    </> :
                    <>
                        <Formik
                            validationSchema={signUpValidationSchema}
                            enableReinitialize={true}
                            initialValues={{ createpassword: "", confirmPassword: "" }}
                            onSubmit={async (values) => {
                                const data = {
                                    userName: username,
                                    password: values.createpassword
                                }
                                axios.put(`${URL.check}/resetPassword/parimitha`, data, {
                                    headers: {
                                        'realm': 'parimitha'
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200 || res.status === 201) {
                                            alert('Your password has been successfully changed.')
                                            handleDonePage()
                                        }
                                    })
                            }}
                        >
                            {
                                ({
                                    handleSubmit,
                                    isValid,
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    touched,
                                    isSubmitting,
                                    setFieldValue,
                                    setFieldError,
                                    setFieldTouched
                                }) => (
                                    <>
                                        <DialogContent>
                                            <div className='px-2'>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <div >
                                                            <Box>
                                                                <label style={{ fontWeight: 500, fontSize: '14px' }}> New Password</label>
                                                            </Box>
                                                            <CustomTextField
                                                                // label="Display name"
                                                                placeholder="New Password"
                                                                borderRadius="7px"
                                                                type={showPassword ? 'text' : 'password'}
                                                                borderColor="#59D8FA"
                                                                icon={showPassword ? <VisibilityOff /> : <Visibility />}

                                                                value={values.createpassword}

                                                                onChange={handleChange('createpassword')}
                                                                handleBlur={handleBlur('createpassword')}
                                                                onClick={handleClickShowPassword}
                                                                error={touched.createpassword && Boolean(errors.createpassword)}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        handleSubmit();
                                                                    }
                                                                }}
                                                            />
                                                            {errors.createpassword && touched.createpassword && (
                                                                <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                                    {touched.createpassword && errors.createpassword}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        <div className='mt-1'>
                                                            <Box>
                                                                <label style={{ fontWeight: 500, fontSize: '14px' }}>Confirm Password</label>
                                                            </Box>
                                                            <CustomTextField
                                                                // label="Display name"
                                                                placeholder="Re-enter your Password"

                                                                type={showConPassword ? 'text' : 'password'}
                                                                borderColor="#59D8FA"
                                                                icon={showConPassword ? <VisibilityOff /> : <Visibility />}
                                                                borderRadius="7px"
                                                                value={values.confirmPassword}

                                                                onChange={handleChange('confirmPassword')}
                                                                handleBlur={handleBlur('confirmPassword')}
                                                                onClick={handleClickShowConPassword}
                                                                error={touched.confirmPassword && Boolean(errors.confirmPassword)}

                                                            />
                                                            {errors.confirmPassword && touched.confirmPassword && (
                                                                <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                                    {touched.confirmPassword && errors.confirmPassword}
                                                                </Typography>
                                                            )}
                                                        </div>

                                                    </Grid>

                                                </Grid>
                                            </div>

                                        </DialogContent >
                                        <Divider />

                                        <DialogActions sx={{ m: "5px 20px" }}>
                                            <div>
                                                <OutlinedButton text="Done" borderColor="#1B5775" height="33px" fontSize="14px" color="#1B5775" borderRadius="7px" onClick={handleSubmit} />
                                            </div>
                                        </DialogActions>
                                    </>
                                )}
                        </Formik>
                    </>
                }

            </CommonDialog >
        </div >
    )
}

export default ForgotPassword;