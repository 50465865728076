import React, { useEffect, useState } from 'react'
import BranchUrl from '../../services/api_branch_url';
import { localstore } from '../localstore/localstore';
import axios from 'axios';
import { Avatar, Grid, Typography } from '@mui/material';
import { calculateAge } from '../atoms/calculateAge';

function LinkedProfiles({ person, handleSwitchProfile, StringAvatar }) {

    const [patient, setPatient] = useState('');
    const patientIbmId = person?.target?.reference;
    const urls = BranchUrl();
    const usertoken = localstore.getToken();

    useEffect(() => {
        if (patientIbmId && usertoken) {
            axios.get(`${urls.person_tenant_orgId}${patientIbmId}`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'Realm': 'parimitha',
                    'Authorization': `Bearer ${usertoken}`
                }
            }).then(res => {
                setPatient(res.data);
            }).catch(err => {
                console.error(err);
            });
        }
    }, [patientIbmId, usertoken, urls.person_tenant_orgId]);




    return (
        <div className='cursor-pointer' >
            <Grid container mt={2} alignItems="center">
                <Grid item xs={10} sm={11} md={3} className="flex items-center" gap={"20px"}>
                    <Avatar {...StringAvatar(`${patient?.name?.[0]?.given} ${patient?.name?.[0]?.family}`)} />
                    <div>
                        <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                            {patient?.name?.[0]?.given} {patient?.name?.[0]?.family}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={10} sm={11} md={2} className="flex items-center" gap={"20px"}>
                    <div>
                        <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                            {patient?.gender}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={10} sm={11} md={3} className="flex items-center" gap={"20px"}>
                    <div>
                        <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                            {patient?.birthDate}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={10} sm={11} md={3} className="flex items-center" gap={"20px"}>
                    <div>
                        <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                            {patient?.telecom?.[0]?.value}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};



export default LinkedProfiles