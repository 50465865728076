import React, { useEffect, useState } from 'react'
import CommonDialog from '../atoms/commonModal';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material';
import { OutlinedButton } from '../atoms/commonbutton';
import URL from '../../services/api_url';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";
import QRcode from 'qrcode.react'
import { Close, Directions } from '@mui/icons-material';
import { FiPhoneCall } from 'react-icons/fi';
import BranchUrl from '../../services/api_branch_url';

function VisitClinic({ open, setOpenClinic, appointmentDetails }) {
    const selectedbranch = useSelector(state => state.SelectedBranch.selectedbranch)
    const [orgData, setOrgData] = useState({})
    const [mapCenter, setMapCenter] = useState({ lat: "", lng: "" });
    const [loc, setLoc] = useState({ lat: "", lng: "" });
    const mapContainerStyle = {
        width: '100%',
        height: '200px'
    };
    const baseurl = BranchUrl()

    useEffect(() => {
        axios.get(`${baseurl.fhir_tenant_orgId}Location`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'Realm': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`
            }
        }).then((res) => {
            setOrgData(res?.data?.entry?.[0]?.resource)
        }).catch((err) => console.log(err))
    }, [selectedbranch])
    useEffect(() => {
        // Update map center and location when orgData changes
        if (orgData && orgData.position) {
            const latitude = parseFloat(orgData.position.latitude);
            const longitude = parseFloat(orgData.position.longitude);
            if (!isNaN(latitude) && !isNaN(longitude)) {
                setLoc({ lat: latitude, lng: longitude });
                setMapCenter({ lat: latitude, lng: longitude });
            }
        }
    }, [orgData]);
    const handleClose = () => {
        setOpenClinic(false)
    }
    return (
        <CommonDialog maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle sx={{ display: 'flex', p: '3px 20px', justifyContent: 'space-between' }}>
                <div>Visit Clinic</div>
                <IconButton onClick={handleClose}><Close sx={{ color: 'red' }} /></IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box sx={{ mt: "5px", border: "2px solid #707070" }}>
                    {
                        mapCenter &&
                        <LoadScript googleMapsApiKey={`${process.env.REACT_APP_LOCATION_KEY}`}>
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                center={mapCenter}
                                zoom={18}
                            >
                                <Marker position={mapCenter} />
                            </GoogleMap>
                        </LoadScript>

                    }
                    {/* { lat: orgData?.position?.longitude, lng: orgData?.position?.latitude } */}
                    {/* <MapLocation

                locationBranch={locationBranch}
                lat={latitude} lng={longitude}
              // locations={locations?.filter((item, i) => item?.[0]?.toLocaleLowerCase() === locationBranch?.toLocaleLowerCase())}
              // center={center} zoom={zoom}
              // locations={["kompally", 17.518422339582578, 78.48276319462754]}
              /> */}
                </Box>
                <div className='mt-1'>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={6} sm={6} md={4}>
                            <div className='text-md fntwt-500 capitalize'>{appointmentDetails?.appointment?.participant?.[1]?.actor?.display}</div>
                            <div className='text-sm' mt="2px">{appointmentDetails?.PractitionerRole?.specialty?.[0]?.coding?.[0]?.display || ''}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={8} >
                            <div className='text-md fntwt-500 capitalize'>{appointmentDetails?.appointment?.appointmentType?.coding?.[0]?.code.toLowerCase()}</div>
                            <div className='text-sm' mt="2px">{appointmentDetails?.appointment?.reasonCode?.[0]?.text}</div>
                        </Grid>
                    </Grid>
                </div>
                <div className='flex items-center justify-between mt-2'>
                    <div></div>
                    <Button endIcon={<Directions />} variant="contained" sx={{ textTransform: 'capitalize', height: "33px", width: 300, gap: 2, borderRadius: "10px", bgcolor: "#1D5775", }}>
                        <a
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                            href={`https://www.google.com/maps?q=${mapCenter?.lat},${mapCenter?.lng}`}
                        >
                            Get Directions

                        </a>
                    </Button >
                    <OutlinedButton text="Call" height="33px" borderRadius="10px" borderColor="#1D5775" color="#1D5775" startIcon={<FiPhoneCall />} />
                </div>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'space-between', p: '10px 20px' }}>
                <div className='flex gap-3'>
                    <OutlinedButton text="Re-Schedule" height="33px" borderRadius="10px" borderColor="#ED70AC" color="#1D5775" />
                    <OutlinedButton text="Delete" height="33px" width="100px" borderRadius="10px" borderColor="#B31412" color='#B31412' />
                </div>
                <OutlinedButton text="Done" height="33px" borderRadius="10px" borderColor="#1D5775" color="#1D5775" />
            </DialogActions>
        </CommonDialog>
    )
}

export default VisitClinic;