import React, { useEffect, useState } from 'react';
import './healthportal.css';
import { Paper, Box, Grid, Button, Avatar, useMediaQuery, Typography, MenuItem, Menu, FormControl, InputLabel, Select, Tooltip, CircularProgress } from "@mui/material";
import SearchComponent from '../header/search';
import TeleImg from '../../assets/images/consult online.svg';
import hospitalimage from '../../assets/hospitalimage.jpg';
import { IoSearchOutline } from 'react-icons/io5';
import { useNavigate, Outlet, useLocation } from 'react-router-dom'

import SearchStrongIcon from '../../assets/healthportal-icons/search-strong.svg'
import NewProfileIcon from '../../assets/healthportal-icons/New Profile.svg'
import LogoutIcon from '../../assets/healthportal-icons/logout-outlined.svg'
import CareReminders from './carereminders';
import Switchprofile from './switchprofile';
import ContainedButton from '../atoms/commonbutton';
import UserService from '../../services/UserService';
import { localstore } from '../localstore/localstore';
import URL from '../../services/api_url';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getFixedPatient, getPatientdatafromibm, getSwitchProfiles } from '../../redux/actions/actions';
import AddProfile from './AddProfile';
import BranchUrl from '../../services/api_branch_url';
import StringAvatar from '../atoms/stringAvatar';
import { calculateAge } from '../atoms/calculateAge';

function HealthPortal() {
    const isXsScreen = useMediaQuery("(max-width:900px)");
    const location = useLocation()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
    const personDetails = useSelector((state) => state.paramitha.patientDetails)
    // console.log(personDetails, "persondetails")
    const patientDetails = useSelector((state) => state.paramitha.patientData)
    const fixedPatient = useSelector(state => state.paramitha.fixedPatient)
    const relatedPerson = useSelector(state => state.paramitha.switchProfiles)
    const [profileOpen, setProfileOpen] = useState(false)
    const usertoken = localstore.getToken()
    const ibmId = localstore.getIbmId()
    const fixedIbmId = localstore.getFixedIbmId()
    const patientIbmId = localstore.getPatientIbmId()
    const urls = BranchUrl()
    const navigate = useNavigate()
    const [age, setAge] = useState('')
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false); // Add loading state
    // const [userData, setUserData] = useState('')
    useEffect(() => {
        if (usertoken && ibmId) {
            dispatch(getPatientdatafromibm(urls, usertoken, ibmId))
        }
    }, [usertoken, ibmId])

    useEffect(() => {
        if (usertoken && fixedIbmId) {
            dispatch(getFixedPatient(urls, usertoken, fixedIbmId))
            // dispatch(getSwitchProfiles(urls, fixedIbmId, usertoken));
        }
    }, [usertoken, fixedIbmId])


    useEffect(() => {

        let d = new Date().getFullYear();
        let birthyear = fixedIbmId !== patientIbmId ?
            patientDetails?.birthDate ? patientDetails.birthDate.split('-')[0] : null : personDetails?.birthDate ? personDetails.birthDate.split('-')[0] : null;
        let age = birthyear ? d - Number(birthyear) : null;

        setAge(age);
    }, [fixedIbmId !== patientIbmId ? patientDetails : personDetails])




    // useEffect(() => {
    //     const fetchPatientData = async () => {
    //         try {
    //             const response = await axios.get(`${urls.person_tenant_orgId}Patient/${patientIbmId}`, {
    //                 headers: {
    //                     'X-FHIR-TENANT-ID': selectedbranch?.tenant,
    //                     'X-FHIR-DSID': `fhir${selectedbranch?.tenant}`,
    //                     'realm': selectedbranch?.tenant,
    //                     'Authorization': `Bearer ${usertoken}`
    //                 },
    //             });

    //             setUserData(response.data)
    //         } catch (error) {
    //             console.error(error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     }
    //     fetchPatientData()

    // }, [patientIbmId])

    const handleLogout = () => {
        localStorage.removeItem("kctoken");
        localStorage.removeItem("IbmId");
        localStorage.removeItem("rctoken")
        localStorage.removeItem("userName")
        localStorage.removeItem("participantId")
        localStorage.removeItem("visitorId")
        localStorage.removeItem("pId")
        localStorage.removeItem("roomId")
        localStorage.removeItem("userId")
        localStorage.removeItem("patientName")
        // navigate('/')
        window.location.href = '/';
        // window.location.reload(true);
    }

    return (
        <Box sx={{
            p: {
                xs: '10px 10px', sm: '10px 10px', md: '20px 50px', lg: "20px 90px"
            }
        }}>
            {/* <div style={{
                position: 'sticky',
                top: 80,
                zIndex: 10,
            }}> */}

            {/* <Paper sx={{ p: "10px", borderRadius: '5px' }}>
                <Grid
                    container
                    className="flex justify-between items-center"
                    sx={{
                        padding: { xs: '0px 5px', md: "0px 10px" },
                        position: "relative",
                    }}
                >
                    <Grid item xs={8} sm={4} md={4} className="flex items-center" gap={"10px"}>
                        {fixedIbmId !== patientIbmId ?
                            <>
                                <Avatar variant="rounded" {...StringAvatar(`${`${patientDetails?.name?.[0]?.given?.[0].toUpperCase()} ${patientDetails?.name?.[0]?.family.toUpperCase() ?? ''}`.slice(0, 12)}${`${patientDetails?.name?.[0]?.given?.[0].toUpperCase()} ${patientDetails?.name?.[0]?.family.toUpperCase() ?? ''}`.length > 12 ? '...' : ''}`)} />

                                <div>
                                    <Typography
                                        fontSize={'14px'}
                                        fontWeight={500}

                                        textTransform={'capitalize'}

                                    >
                                        {`${patientDetails?.name?.[0]?.given?.[0]} ${patientDetails?.name?.[0]?.family ? patientDetails?.name?.[0]?.family : ''}`.slice(0, 12)}
                                        {`${patientDetails?.name?.[0]?.given?.[0]} ${patientDetails?.name?.[0]?.family ? patientDetails?.name?.[0]?.family : ''}`.length > 12 ? '...' : ''}
                                    </Typography>
                                    <Typography fontSize={'14px'}>
                                        {patientDetails?.birthDate ? `${calculateAge(patientDetails?.birthDate)} Y` : ''}/{patientDetails?.gender && patientDetails?.gender.charAt(0).toUpperCase()}
                                        <Typography component={'span'} fontSize={'14px'} color={'#0D00AD'} style={{ marginLeft: '4px' }}>Link ABHA</Typography>
                                    </Typography>
                                </div>
                            </>
                            :
                            <>
                                <Avatar variant="rounded" {...StringAvatar(`${`${personDetails?.name?.[0]?.given?.[0].toUpperCase()} ${personDetails?.name?.[0]?.family.toUpperCase() ?? ''}`.slice(0, 12)}${`${personDetails?.name?.[0]?.given?.[0].toUpperCase()} ${personDetails?.name?.[0]?.family.toUpperCase() ?? ''}`.length > 12 ? '...' : ''}`)} />

                                <div>
                                    <Typography
                                        fontSize={'14px'}
                                        fontWeight={500}

                                        textTransform={'capitalize'}

                                    >
                                        {`${personDetails?.name?.[0]?.given?.[0]} ${personDetails?.name?.[0]?.family ? personDetails?.name?.[0]?.family : ''}`.slice(0, 12)}
                                        {`${personDetails?.name?.[0]?.given?.[0]} ${personDetails?.name?.[0]?.family ? personDetails?.name?.[0]?.family : ''}`.length > 12 ? '...' : ''}
                                    </Typography>
                                    <Typography fontSize={'14px'}>
                                        {personDetails?.birthDate ? `${calculateAge(personDetails?.birthDate)} Y` : ''}/{personDetails?.gender && personDetails?.gender.charAt(0).toUpperCase()}
                                        <Typography component={'span'} fontSize={'14px'} color={'#0D00AD'} style={{ marginLeft: '4px' }}>Link ABHA</Typography>
                                    </Typography>
                                </div>
                            </>
                        }

                    </Grid>

                    <Grid item md={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                        {!isXsScreen &&
                            <Paper elevation={5} className='paperIcons' sx={{ borderRadius: '25px', width: { xs: 20, sm: 35, md: 40 }, height: { xs: 20, sm: 35, md: 40 }, padding: '10px', marginRight: '15px' }}>
                                <img src={SearchStrongIcon} alt="search" />
                            </Paper>
                        }
                        <Paper elevation={5} className='paperIcons' sx={{ borderRadius: '25px', width: { xs: 35, sm: 35, md: 40 }, height: { xs: 35, sm: 35, md: 40 }, padding: '10px', marginRight: '15px' }}>
                            <img src={TeleImg} alt="tele" />
                        </Paper>

                        <Paper elevation={5} sx={{ borderRadius: '25px', width: { xs: 35, sm: 35, md: 40 }, height: { xs: 35, sm: 35, md: 40 }, padding: '10px', cursor: 'pointer' }} onClick={handleLogout} >
                            <Tooltip title="Logout">
                                <img src={LogoutIcon} alt="logout" />
                            </Tooltip>
                        </Paper>
                    </Grid>

                </Grid>
            </Paper> */}

            {/* </div> */}
            <Grid container>
                <Grid item xs={12} md={4} lg={3} mt={2}>
                    {/* {!isXsScreen && ( */}

                    <Box sx={{ borderRadius: '5px' }}>
                        <Typography fontSize={14} fontWeight={500} sx={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: 21 }}><img src={NewProfileIcon} alt="add" />
                            Add New Profile
                        </Typography>
                        <Paper elevation={3} sx={{ p: '10px', mt: 2, textAlign: 'center' }}>
                            <Typography fontSize={14}> Need to Manage Health ID for your family members ?</Typography>
                            <ContainedButton text="New Profile" startIcon={<img src={NewProfileIcon} alt="newprofile" />} bgcolor="#205072" mt={"10px"} height="33px" borderRadius="10px" width="150px" onClick={() => setProfileOpen(true)} />
                        </Paper>
                    </Box>
                    {/* )} */}
                    <AddProfile open={profileOpen} setProfileOpen={setProfileOpen} selectedbranch={selectedbranch} />
                </Grid>
                <Grid item xs={12} md={8} lg={9} mt={2}>

                    {/* {!isXsScreen && */}
                    <Switchprofile relatedPerson={relatedPerson} selectedbranch={selectedbranch} fixedPatient={fixedPatient} loading={loading} setLoading={setLoading} personDetails={personDetails} />
                    {/* //  } */}
                </Grid>
            </Grid>

            {/* <>
                        {isXsScreen && (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: "15px", p: '0px 5px' }}>
                                {selectedTab === "healthportal" ?
                                    <Headertitle title="Health profile" icon={HealthprofileIcon} />
                                    : selectedTab === "consultations" ?
                                        <Headertitle title="Consultations" icon={ConsultationsIcon} />
                                        : selectedTab === "medicalrecords" ?
                                            <Headertitle title="Medical Records" icon={MedicalRecordIcon} />
                                            : selectedTab === "medication" ?
                                                <Headertitle title="Medication Orders" icon={MedicationIcon} />
                                                : selectedTab === "diagnostic" ?
                                                    <Headertitle title="Diagnostic Reports" icon={DiagnosticIcon} />
                                                    : selectedTab === "paybill" ?
                                                        <Headertitle title="Pay your Bill Online" icon={PaybillIcon} />
                                                        : null
                                }

                                <FormControl >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedTab}
                                        label="Select Tab"
                                        onChange={handleTabChange}
                                        variant='standard'
                                    >
                                        <MenuItem value="healthportal">My Health Profile</MenuItem>
                                        <MenuItem value="consultations">Consultations</MenuItem>
                                        <MenuItem value="medicalrecords">Medical Records</MenuItem>
                                        <MenuItem value="medication">Medication Orders</MenuItem>
                                        <MenuItem value="diagnostic">Diagnostic Reports</MenuItem>
                                        <MenuItem value="paybill">Pay your Bill Online</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    </>
                    <Grid container sx={{ mt: "15px", xs: '2px' }}>

                        <Grid item md={2.5} >
                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                    flexDirection: { md: 'column' },
                                    gap: 1,
                                    // mt: { sm: '80px', xs: '0px', md: "60px", },
                                    // position: 'fixed',
                                    p: "10px 10px 0px 0px "
                                }}
                            >

                                <TabItem
                                    icon={HealthprofileIcon}
                                    label="My Health Profile"
                                    selected={selectedTab === 'healthportal'}
                                    onClick={() => handleTabClick("healthportal")}
                                />

                                <TabItem
                                    icon={ConsultationsIcon}
                                    label="Consultations"
                                    selected={selectedTab === 'consultations'}
                                    onClick={() => handleTabClick("consultations")}
                                />

                                <TabItem
                                    icon={MedicalRecordIcon}
                                    label="Medical Records"
                                    selected={selectedTab === 'medicalrecords'}
                                    onClick={() => handleTabClick("medicalrecords")}
                                />
                                <TabItem
                                    icon={MedicationIcon}
                                    label="Medication Orders"
                                    selected={selectedTab === 'medication'}
                                    onClick={() => handleTabClick("medication")}
                                />
                                <TabItem
                                    icon={DiagnosticIcon}
                                    label="Diagnostic Reports"
                                    selected={selectedTab === 'diagnostic'}
                                    onClick={() => handleTabClick("diagnostic")}
                                />
                                <TabItem
                                    icon={PaybillIcon}
                                    label="Pay your Bill Online"
                                    selected={selectedTab === 'paybill'}
                                    onClick={() => handleTabClick("paybill")}
                                />
                            </Box>
                            {!isXsScreen && <Switchprofile relatedPerson={relatedPerson} selectedbranch={selectedbranch} fixedPatient={fixedPatient} loading={loading} setLoading={setLoading} stringAvatar={stringAvatar} />}
                            {!isXsScreen && (
                                <Paper sx={{ p: '10px', mt: 2 }}>
                                    <Typography fontSize={14}> Need to Manage Health ID for your family members ?</Typography>
                                    <ContainedButton text="New Profile" startIcon={<img src={NewProfileIcon} alt="newprofile" />} bgcolor="#205072" mt={"10px"} height="33px" borderRadius="10px" width="150px" onClick={() => setProfileOpen(true)} />
                                </Paper>
                            )}
                            <AddProfile open={profileOpen} setProfileOpen={setProfileOpen} selectedbranch={selectedbranch} patientDetails={patientDetails} />
                        </Grid>
                        <Grid item md={7} xs={12} sm={12} p={{ xs: '1px 10px', sm: '1px 10px', md: "5px 30px" }}>
                            <Outlet />
                        </Grid>
                        <Grid item md={2.5} >

                            {!isXsScreen &&
                                <CareReminders />}
                        </Grid>
                    </Grid> */}
        </Box >
    )
}

export default HealthPortal;