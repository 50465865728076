import React, { useEffect, useState } from 'react'
import CommonDialog from '../../atoms/commonModal'
import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, MenuItem, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Field, Formik } from 'formik'
import * as yup from 'yup';
import CustomAutoComplete from '../../atoms/CustomAutoComplete'
import { CustomTextField } from '../../atoms/TextField'
import { useDispatch } from 'react-redux'
import ContainedButton, { OutlinedButton } from '../../atoms/commonbutton'
import zIndex from '@mui/material/styles/zIndex'
import SelectOptions from '../../atoms/selectoptions'
import dayjs from 'dayjs'
import FormikCommoncalender, { Commoncalender } from '../../atoms/commoncalender'
import axios from 'axios'
import { localstore } from '../../localstore/localstore'
import URL from '../../../services/api_url'
import { getPatientdatafromibm, getPatientIdDetails } from '../../../redux/actions/actions'
import ErrorStatusMsg from '../../atoms/ErrorStatusMsg'
import BranchUrl from '../../../services/api_branch_url'

function ContactsUpdate({ open, setEditContact, selectedbranch, patientDetails }) {
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [mobile, setMobile] = useState('')
    const [emailId, setEmailId] = useState('')
    const [emailError, setEmailError] = useState('');

    const [language, setLanguage] = useState([])
    const ibmId = localstore.getPatientIbmId()

    const usertoken = localstore.getToken()
    const dispatch = useDispatch()
    const urls = BranchUrl()
    const phoneNumber = patientDetails?.telecom?.[0]?.value
    const email = patientDetails?.telecom?.[1]?.value
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setEmailId(email);

        if (email && !validateEmail(email)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    };
    const handleClose = () => {
        setEditContact(false)
        setAlertmessage('')
    }
    useEffect(() => {
        setMobile(phoneNumber || '')
        setEmailId(email || '')
        const communication = patientDetails?.communication?.map(options => options?.language?.coding?.[0])
        setLanguage(communication)
    }, [patientDetails])
    // console.log(emailId, "email")
    const handleSave = async () => {
        const data =
        {
            ...patientDetails,
            // "telecom": [
            //     {
            //         "system": "phone",
            //         "value": phoneNumber,
            //         "use": "work"
            //     },
            //     {
            //         "system": "email",
            //         "value": emailId,
            //         "use": "work"
            //     },

            // ],
            "telecom": [
                {
                    "use": "mobile",
                    "value": phoneNumber,
                    "system": "phone"
                },
                email ? {
                    "use": "home", // Typically, "home" or "work" is used for email
                    "value": emailId,
                    "system": "email"
                } : null
            ].filter(Boolean),
            // "resourceType": "Patient",
            // "id": ibmId,
            // "active": true,
            // "address": patientDetails?.address ? [
            //     {
            //         "use": "home",
            //         "type": "both",
            //         // "text": "534 Erewhon St PeasantVille, Rainbow, Vic  3999",
            //         "line": [
            //             patientDetails?.address?.[0]?.line[0], patientDetails?.address?.[0]?.line[1]
            //         ],
            //         "city": patientDetails?.address?.[0]?.city,
            //         "district": patientDetails?.address?.[0]?.city,
            //         "state": patientDetails?.address?.[0]?.state,
            //         "postalCode": patientDetails?.address?.[0]?.postalCode,

            //     }
            // ] : [],
            // "identifier": patientDetails?.identifier ? [
            //     {
            //         "system": patientDetails?.identifier?.[0]?.system,
            //         "value": patientDetails?.identifier?.[0]?.value
            //     }
            // ] : [],
            // "name": patientDetails?.name ? [
            //     {
            //         "use": "official",
            //         "family": patientDetails?.name?.[0]?.family,
            //         "text": patientDetails?.name?.[0]?.given[0],
            //         "given": [patientDetails?.name?.[0]?.given[0]]
            //     }
            // ] : [],


            // "telecom": [
            //     {
            //         "system": "phone",
            //         "value": phoneNumber,
            //         "use": "work"
            //     },
            //     {
            //         "system": "email",
            //         "value": emailId,
            //         "use": "work"
            //     },

            // ],
            // "gender": patientDetails?.gender,
            // "birthDate": patientDetails?.birthDate,
            // "managingOrganization": {
            //     "reference": `Organization/${selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`}`
            // },
            // "contact": patientDetails?.contact ?
            //     [
            //         {
            //             "relationship": [
            //                 {
            //                     "coding": [
            //                         {
            //                             "system": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[0]?.system,
            //                             "code": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[0]?.code
            //                         },
            //                         {
            //                             "system": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.system,
            //                             "code": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.code,
            //                             "display": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.display
            //                         },

            //                     ]
            //                 }
            //             ],
            //             "name": {
            //                 "family": patientDetails?.contact?.[0]?.name?.family,
            //                 "_family": {
            //                     "extension": [
            //                         {
            //                             "url": "http://hl7.org/fhir/StructureDefinition/humanname-own-prefix",
            //                             "valueString": "VV"
            //                         }
            //                     ]
            //                 },
            //                 "given": patientDetails?.contact?.[0]?.name?.given
            //             },
            //             "telecom": [
            //                 {
            //                     "system": "phone",
            //                     "value": patientDetails?.contact?.[0]?.telecom?.[0].value || ''
            //                 },
            //                 {
            //                     "system": "email",
            //                     "value": patientDetails?.contact?.[0]?.telecom?.[1].value || ''
            //                 }
            //             ],
            //             "address": {
            //                 "use": "home",
            //                 "type": "both",
            //                 "line": [
            //                     patientDetails?.contact?.[0]?.address?.line[0] || '', patientDetails?.contact?.[0]?.address?.line[1] || ''
            //                 ],
            //                 "city": patientDetails?.contact?.[0]?.address?.city,
            //                 "district": patientDetails?.contact?.[0]?.address?.district,
            //                 "state": patientDetails?.contact?.[0]?.address?.state,
            //                 "postalCode": patientDetails?.contact?.[0]?.address?.postalCode || '',
            //             },
            //             "gender": patientDetails?.contact?.[0]?.gender,
            //         }
            //     ] : [],
            // "communication": language ? language?.map(options => {
            //     return {
            //         'language': {
            //             "coding": [
            //                 {
            //                     "system": "urn:ietf:bcp:47",
            //                     "code": options.code,
            //                     "display": options.display
            //                 }
            //             ],
            //         }
            //     }
            // }) : []
        }

        try {
            setLoading(true)
            const res = await axios.put(`${urls.person_tenant_orgId}Patient/${ibmId}`, data, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'realm': 'parimitha',
                    'Authorization': `Bearer ${usertoken}`
                }
            })
            setAlertmessage('updated successfully')
            setAlertstatus('success')
            dispatch(getPatientIdDetails(urls, usertoken, ibmId))
            handleClose()
        } catch (error) {
            setAlertmessage(error.message)
            setAlertstatus('error')
        }
        finally {
            setLoading(false)
        }
    }
    return (

        <div>
            <CommonDialog open={open} maxWidth="md" onClose={handleClose} sx={{ '.MuiDialog-paper': { top: { xs: 0, sm: 0, md: -122 } } }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Contact Details</Typography>
                    <IconButton onClick={handleClose}><Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div className='px-2'>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6}>

                                <Box >
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Mobile Number</label>
                                    </Box>
                                    <CustomTextField placeholder="Enter Mobile Number " type={'text'} borderColor="#59D8FA" borderRadius="7px" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box >
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Email Address</label>
                                    </Box>
                                    <CustomTextField
                                        placeholder="Email Address"
                                        type={'email'}
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={emailId}
                                        onChange={handleEmailChange}
                                    />
                                    {emailError && (
                                        <Typography color="error" style={{ fontSize: '12px' }}>
                                            {emailError}
                                        </Typography>
                                    )}
                                </Box>

                            </Grid>
                        </Grid>
                    </div>

                </DialogContent >
                <Divider />
                <div className='text-center'> <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>

                <DialogActions sx={{ m: "5px 20px" }}>
                    <div>
                        <OutlinedButton text="Save Changes" borderColor="#1B5775" color="#1B5775" borderRadius="7px" height="33px" endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} onClick={handleSave} />
                    </div>
                </DialogActions>

            </CommonDialog >

        </div >
    )
}

export default ContactsUpdate