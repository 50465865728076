import React from 'react'

function Allergies() {

    return (


        <div className='p-2 text-center'>
            Coming Soon
        </div>
    )
}

export default Allergies