import React, { useEffect, useState } from 'react'
import CommonDialog from '../../atoms/commonModal'
import { Autocomplete, Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Field, Formik } from 'formik'
import * as yup from 'yup';
import CustomAutoComplete from '../../atoms/CustomAutoComplete'
import { CustomTextField } from '../../atoms/TextField'
import { useDispatch, useSelector } from 'react-redux'
import ContainedButton, { OutlinedButton } from '../../atoms/commonbutton'
import zIndex from '@mui/material/styles/zIndex'
import SelectOptions from '../../atoms/selectoptions'
import dayjs from 'dayjs'
import FormikCommoncalender, { Commoncalender } from '../../atoms/commoncalender'
import { localstore } from '../../localstore/localstore'
import axios from 'axios'
import URL from '../../../services/api_url'
import ErrorStatusMsg from '../../atoms/ErrorStatusMsg'
import { getLanguages, getPatientdatafromibm, getPatientIdDetails } from '../../../redux/actions/actions'
import BranchUrl from '../../../services/api_branch_url'

function InformationUpdate({ open, setEditInfo, selectedbranch, patientDetails }) {
    const genderOptions = ["male", "female", "other"];
    const languagesOptions = ['English', 'Telugu', 'Hindi']
    const [firstname, setFirstname] = useState('')
    const [middlename, setMiddlename] = useState('')
    const [lastname, setLastname] = useState('')
    const [address, setAddress] = useState('')
    const [gender, setGender] = useState('');
    const [selectLanguage, setSelectLanguage] = useState([]);

    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [loading, setLoading] = useState(false)
    // const genderdata = ['Male', 'Female', 'Other'];
    const [birthDate, setBirthDate] = useState('');
    const communication = useSelector(state => state.paramitha.languages)
    const languages = communication?.concept
    const dispatch = useDispatch()
    // console.log(selectLanguage, "select language")
    const ibmId = localstore.getIbmId()
    const patientIbmId = localstore.getPatientIbmId()
    const usertoken = localstore.getToken()
    const urls = BranchUrl()
    const firstName = patientDetails?.name?.[0]?.given[0] || ''

    const lastName = patientDetails?.name?.[0]?.family ? patientDetails?.name?.[0]?.family : ''
    const middleName = ''
    const handleClose = () => {
        setEditInfo(false)
        setAlertmessage('')
    }

    useEffect(() => {
        dispatch(getLanguages())
    }, [])
    useEffect(() => {
        setFirstname(firstName)
        setLastname(lastName)
        setMiddlename(middleName)
        setBirthDate(patientDetails.birthDate || '')
        setGender(patientDetails?.gender || '')
        let languageValues = patientDetails?.communication?.length > 0
            ? patientDetails?.communication?.map(option => option?.language?.coding?.[0])
            : [];
        setSelectLanguage(languageValues);
    }, [patientDetails])
    const handleGender = (e) => {
        // console.log(selectedGender, "select")
        setGender(e.target.value);
    }
    const handleSelectLanguage = (e, value) => {
        // console.log(selectedGender, "select")
        setSelectLanguage(value);
    }
    const handleChangeDate = (newvalue) => {
        setBirthDate(newvalue?.format("YYYY-MM-DD"))
    }

    const handleSaveDetails = async () => {
        const data =
        {
            ...patientDetails,
            "name": [
                {
                    "use": "official",
                    "family": lastname,
                    "text": `${firstname}`,
                    "given": [`${firstname}`]
                }
            ],
            "gender": gender,
            "birthDate": birthDate,

            "communication": selectLanguage ? selectLanguage?.map((options) => {
                return {
                    'language': {
                        "coding": [
                            {
                                "system": "urn:ietf:bcp:47",
                                "code": options.code,
                                "display": options.display
                            }
                        ],
                    }
                }
            }) : [],
            // "resourceType": "Patient",
            // "id": patientIbmId,
            // "address": patientDetails.address ? [
            //     {
            //         "use": "home",
            //         "type": "both",
            //         // "text": "534 Erewhon St PeasantVille, Rainbow, Vic  3999",
            //         "line": [
            //             patientDetails?.address?.[0]?.line[0], patientDetails?.address?.[0]?.line[1]
            //         ],
            //         "city": patientDetails?.address?.[0]?.city,
            //         "district": patientDetails?.address?.[0]?.city,
            //         "state": patientDetails?.address?.[0]?.state,
            //         "postalCode": patientDetails?.address?.[0]?.postalCode,

            //     }
            // ] : [],
            // "identifier": patientDetails?.identifier ? [
            //     {
            //         "system": patientDetails?.identifier?.[0]?.system,
            //         "value": patientDetails?.identifier?.[0]?.value
            //     }
            // ] : [],
            // "active": true,
            // "name": [
            //     {
            //         "use": "official",
            //         "family": lastname,
            //         "text": `${firstname}`,
            //         "given": [`${firstname}`]
            //     }
            // ],
            // "telecom": patientDetails?.telecom ? [
            //     {
            //         "system": "phone",
            //         "value": patientDetails?.telecom?.[0]?.value,
            //         "use": "work"
            //     },

            //     // {
            //     //     "system": "email",
            //     //     "value": patientDetails?.telecom?.[1]?.value || '',
            //     //     "use": "work"
            //     // },

            // ] : [],
            // "gender": gender,
            // "birthDate": birthDate,
            // "managingOrganization": {
            //     "reference": `Organization/${selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`}`


            // },
            // "communication": selectLanguage ? selectLanguage?.map((options) => {
            //     return {
            //         'language': {
            //             "coding": [
            //                 {
            //                     "system": "urn:ietf:bcp:47",
            //                     "code": options.code,
            //                     "display": options.display
            //                 }
            //             ],
            //         }
            //     }
            // }) : [],
            // "contact": patientDetails?.contact ?
            //     [
            //         {
            //             "relationship": [
            //                 {
            //                     "coding": [
            //                         {
            //                             "system": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[0]?.system,
            //                             "code": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[0]?.code
            //                         },
            //                         {
            //                             "system": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.system,
            //                             "code": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.code,
            //                             "display": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.display
            //                         },

            //                     ]
            //                 }
            //             ],
            //             "name": {
            //                 "family": patientDetails?.contact?.[0]?.name?.family,
            //                 "_family": {
            //                     "extension": [
            //                         {
            //                             "url": "http://hl7.org/fhir/StructureDefinition/humanname-own-prefix",
            //                             "valueString": "VV"
            //                         }
            //                     ]
            //                 },
            //                 "given": patientDetails?.contact?.[0]?.name?.given
            //             },
            //             "telecom": [
            //                 {
            //                     "system": "phone",
            //                     "value": patientDetails?.contact?.[0]?.telecom?.[0].value || ''
            //                 },
            //                 {
            //                     "system": "email",
            //                     "value": patientDetails?.contact?.[0]?.telecom?.[1].value || ''
            //                 }
            //             ],
            //             "address": {
            //                 "use": "home",
            //                 "type": "both",
            //                 "line": [
            //                     patientDetails?.contact?.[0]?.address?.line[0] || '', patientDetails?.contact?.[0]?.address?.line[1] || ''
            //                 ],
            //                 "city": patientDetails?.contact?.[0]?.address?.city,
            //                 "district": patientDetails?.contact?.[0]?.address?.district,
            //                 "state": patientDetails?.contact?.[0]?.address?.state,
            //                 "postalCode": patientDetails?.contact?.[0]?.address?.postalCode || '',
            //             },
            //             "gender": patientDetails?.contact?.[0]?.gender,
            //         }
            //     ] : [],

        }
        try {
            setLoading(true)
            const res = await axios.put(`${urls.person_tenant_orgId}Patient/${patientIbmId}`, data, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'realm': 'parimitha',
                    'Authorization': `Bearer ${usertoken}`
                }
            })
            setAlertmessage('updated successfully')
            setAlertstatus('success')
            dispatch(getPatientIdDetails(urls, usertoken, patientIbmId))
            handleClose()
        } catch (error) {
            setAlertmessage(error.message)
            setAlertstatus('error')
        }
        finally {
            setLoading(false)
        }

    }
    return (

        <div>
            <CommonDialog open={open} maxWidth="md" onClose={handleClose} sx={{ '.MuiDialog-paper': { top: { xs: 0, sm: 0, md: -5 } } }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Personal Information</Typography>
                    <IconButton onClick={handleClose}><Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{
                    overflowY: 'scroll', height: { xs: '62vh', sm: '61vh', md: '' }, '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                }}>
                    <div className='px-2'>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6}>

                                <Box >
                                    <Box >
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>First Name</label>
                                    </Box>
                                    <CustomTextField placeholder="Enter Your Full Name " type={'text'} borderColor="#59D8FA" borderRadius="7px" value={firstname} onChange={(e) => setFirstname(e.target.value)} />

                                </Box>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={4}>
                                <Box >
                                    <Box >
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Middle Name</label>
                                    </Box>
                                    <CustomTextField
                                        placeholder="Middle Name"
                                        type={'email'}
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={middlename}
                                        onChange={(e) => setMiddlename(e.target.value)}
                                    />

                                </Box>
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={6} >
                                <Box>
                                    <Box >
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Last Name</label>
                                    </Box>
                                    <CustomTextField
                                        // label="Display name"
                                        placeholder="Last Name"
                                        type={'text'}
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={lastname}
                                        onChange={(e) => setLastname(e.target.value)}
                                    />

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box >
                                    <Box >
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Gender</label>
                                    </Box>
                                    <SelectOptions borderRadius="7px" value={gender} defaultName="Select your Gender" onChange={handleGender}>
                                        {genderOptions.map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </SelectOptions>

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} sx={{ '.MuiStack-root': { paddingTop: '0px' } }}>

                                <Box >
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Date of Birth</label>
                                </Box>
                                <Commoncalender width="100%" value={dayjs(birthDate)} onChange={handleChangeDate} maxDate={dayjs()}
                                />


                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>

                                <Box >
                                    <Box  >
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Communication Languages</label>
                                    </Box>
                                    {/* <Autocomplete
                                        id="state-select"
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#59D8FA',
                                                },
                                            },
                                            ".MuiInputBase-root": {
                                                borderRadius: '7px',
                                            },
                                        }}
                                        options={languages}
                                        size='small'
                                        autoHighlight
                                        // value={state}
                                        // onChange={handleChangeState}
                                        fullWidth
                                        getOptionLabel={(option) => option.display}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                                {option.display}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder='Select Language'
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                            />
                                        )}
                                    /> */}
                                    <Autocomplete
                                        multiple
                                        limitTags={2}
                                        id="multiple-limit-tags"
                                        fullWidth
                                        size='small'
                                        options={languages || []}
                                        value={selectLanguage}
                                        getOptionLabel={(option) => option?.display || ""}
                                        // defaultValue={[languages[13], languages[12], languages[11]]}
                                        onChange={handleSelectLanguage}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                // label="limitTags"
                                                placeholder="Communication Language" />
                                        )}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#59D8FA',
                                                },
                                            },
                                            ".MuiInputBase-root": {
                                                borderRadius: '7px',
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>

                </DialogContent >
                <Divider />

                <div className='text-center'> <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                <DialogActions sx={{ m: "10px 20px", }}>
                    <div >
                        <OutlinedButton text="Save Changes" borderColor="#1B5775" color="#1B5775" borderRadius="7px" endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} onClick={handleSaveDetails} />
                    </div>
                </DialogActions>

            </CommonDialog >

        </div >
    )
}

export default InformationUpdate