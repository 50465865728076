const initialState = {
    accesstoken: "",
    branchLists: [],
    postdata: [],
    location: ''
};

const loginReducer = (state = initialState, action) => {
    // console.log(action,">>>>>>>>>>>>>");
    switch (action.type) {
        case "SET_TOKEN":
            return {
                ...state,
                accesstoken: action.payload
            };
        case "BRANCHLISTS":
            return {
                ...state,
                branchLists: action.payload
            };
        case 'POSTBYID':
            return {
                ...state,
                postdata: action.payload,
            };
        case 'LOCATION':
            return {
                ...state,
                location: action.payload,
            };
        default:
            return state;
    }
};

export default loginReducer;
