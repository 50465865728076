import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import SecondHeader, { mainNavItems } from "./secondheader";
import { HideOn } from "react-hide-on-scroll";
import { LensOutlined, LensRounded } from "@mui/icons-material";
import Firstslide from "../home/carouselslides/firstslide";
import Secondslide from "../home/carouselslides/secondslide";
import Thirdslide from "../home/carouselslides/thirdslide";
import Fourthslide from "../home/carouselslides/fourthslide";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";
import { styled } from "@mui/system";
import { animated } from "@react-spring/web";

import styles from "./styles.module.css";
import "./headerstyles.css";
import { Box, Grid } from "@mui/material";
import Fifthslide from "../home/carouselslides/fifthslide";
import { useMediaQuery } from "@mui/material";
import { useDrag, useGesture } from "@use-gesture/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.module.css";
import HelpComponent from "../home/Help";
import HealthServices from "../home/specialists/HealthServices";
import HealthComp from "../home/healhinfor/HealthCom";
import TestimonialPage from "../home/testimonial/testimonial";
import AboutComp from "../home/About";
import FirstSlide1 from "../home/carouselslides/firstSlide1";

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(80% - 130px)",
  cursor: "pointer",
};

const indicatorStyles = {
  width: 18,
  height: 18,
  display: "inline-block",
  margin: "0 8px",
  color: "white",
};

const Header = () => {
  const spanRef = React.useRef();
  const [position, setPosition] = React.useState(0);




  const matches1 = useMediaQuery("(max-width:600px)");
  const matched1 = useMediaQuery("(min-width:768px)");

  const renderCustomLeftArrow = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <div>
        <button
          type="button"
          onClick={onClickHandler}
          title={label}
          style={{ ...arrowStyles, left: 15 }}
        >
          <FaCircleChevronLeft
            style={{ fontSize: "3vw", color: "#E54290" }}
            className={styles.leftarrow}
          />
        </button>
      </div>
    );

  const renderCustomRightArrow = (onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{ ...arrowStyles, right: 15 }}
      >
        <FaCircleChevronRight
          style={{ fontSize: "3vw", color: "#E54290" }}
          className={styles.leftarrow}
        />
      </button>
    );

  const customIndicator = (onClickHandler, isSelected, index, label) => {
    if (isSelected) {
      return (
        <li style={{ ...indicatorStyles }}>
          <LensRounded
            style={{
              fontSize: "16px",
              background: "transparent",
              borderRadius: "50%",
              color: "#E54290",
              // border: "1px solid #E54290",
            }}
            className={styles.carouseldots}
          />
        </li>
      );
    }
    return (
      <li
        style={indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        title={`${label} ${index + 1}`}
        aria-label={`${label} ${index + 1}`}
      >
        <LensRounded
          style={{ fontSize: "16px", color: "#F5C1D9", background: "transparent", }}
          className={styles.carouseldots}
        />
      </li>
    );
  };

  const bind = useDrag(({ swipe: [swipeX] }) => {
    setPosition((p) => Math.min(Math.max(-1, p + swipeX), 1));
  });

  return (
    <>
      <div>
        <div className={styles.header}>
          <Grid className={styles.topHeader}>
            <SecondHeader />
          </Grid>
        </div>
        {/* <animated.div
          style={{ marginTop: -150, marginBottom: 20 }}
          id="carousel"
          className={styles.carouselHead}
        > */}
        <Box sx={{ mt: { xs: -17, sm: -17, md: -19 }, mb: { xs: 0, md: 1 } }} >

          <Carousel
            showArrows
            showThumbs={false}
            renderArrowPrev={renderCustomLeftArrow}
            renderArrowNext={renderCustomRightArrow}
            renderIndicator={customIndicator}
            {...bind()}
          >
            <Firstslide />
            <Secondslide />
            <Thirdslide />
            <Fourthslide />
            <Fifthslide />
          </Carousel>
        </Box>

        {/* </animated.div> */}
        <Box sx={{ padding: { xs: '0px 20px', md: '0px 90px', lg: '0px 100px', xl: '0px 100px' } }}>

          <HelpComponent />
          <HealthServices />
          <HealthComp />
          <TestimonialPage />
          <AboutComp />
        </Box>
      </div >

      {/* <HideOn inverse height={480}>
        <div className={styles.secondheader}>
          <SecondHeader />
        </div>
      </HideOn> */}
    </>
  );
};

export default Header;
