import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import MedicationIcon from '../../assets/healthportal-icons/medication-orders.svg'

function Medication() {
    const isXsScreen = useMediaQuery("(max-width:900px)");


    return (
        <div>

            <Typography color={'#155775'} className='font-medium text-sm fntwt-600 flex align-item' gap={"10px"}><img src={MedicationIcon} alt="medical" /> Medication Orders</Typography>

            <div className='p-10 text-center'>
                Coming Soon
            </div>
        </div>
    )
}

export default Medication