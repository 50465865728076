"use client";
import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  // makeStyles,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import LoginIcon from "@mui/icons-material/Login";
import DoctorImg from '../../assets/images/calendar.svg';
import DoctorImg_wt from '../../assets/doctor/Find Doctor.svg';
import CareImg from '../../assets/images/URGENT CARE.svg';
import CareImg_wt from '../../assets/doctor/Uegetcare.svg';
// import EmergencyImg from '../../assets/images/ambulance-icon.svg';
import EmergencyImg from '../../assets/doctor/ambulance-icon.svg';
import TeleImg from '../../assets/images/consult online.svg';
import TeleImg_wt from '../../assets/doctor/Telemedicine.svg';
import PortalImg from '../../assets/images/login.svg';
import PortalImg_wt from '../../assets/doctor/login.png';
import EmergencyPopup from "../branchhome/EmergencyPopup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UrgentCarePopup from "../branchhome/UrgentcarePopup";
import TelemedicinePopup from "../branchhome/TelemedicinePopup";

const useStyles = makeStyles((theme) => ({
  heading1: {
    textAlign: "center",
    fontWeight: 500,
    color: "#1D5775",
    // textTransform: "uppercase",
  },
  textcolor: {
    // color: "#1D5775",
    fontWeight: "normal",
    // fontSize: "1.3vw",
    fontSize: { xs: '14px', md: '16px' }
  },
  // bodycolor: {
  //   color: "#898989",
  // },
  card: {
    borderRadius: "10px",
    cursor: "pointer",

    backgroundColor: '#FDF3F7 !important',
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#E54290 !important",  // Change to your desired hover background color
      color: "#FFFFFF",            // Change to your desired hover text color
    },
  },
}));

const CardsData = [
  {
    title: "Find Doctor",
    description: "Schedule a Visit",
    icon: <FollowTheSignsIcon Centre sx={{ fontSize: 48, mr: "10px" }} />,
    imgSrc: DoctorImg,
    imgSrchover: DoctorImg_wt,
    url: '/doctors'
  },
  {
    title: "Urgent Care",
    description: "Immediate Consult",
    icon: <FollowTheSignsIcon sx={{ fontSize: 35, mr: "10px" }} />,
    imgSrc: CareImg,
    imgSrchover: CareImg_wt,
  },
  {
    title: "Emergency",
    description: "Ambulance Services",
    icon: <LoginIcon sx={{ fontSize: 50, mr: "10px" }} />,
    imgSrc: EmergencyImg,
    imgSrchover: EmergencyImg,
  },
  {
    title: "Telemedicine",
    description: "Consult Online",
    icon: <LoginIcon sx={{ fontSize: 35, mr: "10px" }} />,
    imgSrc: TeleImg,
    imgSrchover: TeleImg_wt,
  },
  // {
  //   title: "Talk To Experts",
  //   description: "Get answers & Support",
  //   icon: <AddIcCallIcon sx={{ fontSize: 48, mr: "10px" }} />,
  //   imgSrc: "/images/login.svg",
  // },
  {
    title: "Patient Portal",
    description: "Digital Health Login",
    //   icon: <AddIcCallIcon sx={{ fontSize: 48, mr: "10px" }} />,
    imgSrc: PortalImg,
    imgSrchover: PortalImg_wt,
  },
];

const HelpComponent = () => {
  const Classes = useStyles();
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)

  const [emergencyOpen, setEmergencyOpen] = useState(false)
  const [urgentCareOpen, setUrgentCareOpen] = useState(false)
  const [telemedicineOpen, setTelemedicineOpen] = useState(false)
  const [hoveredCard, setHoveredCard] = useState(null);
  const navigate = useNavigate()

  const handleSelectOpen = (title, item) => {
    if (title === "Emergency") {
      // if (selectedbranch?.name) {
      setEmergencyOpen(true)
      // } else {
      //   alert('Please Select the branch')
      // }
    } else if (title === 'Urgent Care') {
      // if (selectedbranch?.name) {
      setUrgentCareOpen(true)
      // } else {
      //   alert('Please Select the branch')
      // }
    } else if (title === 'Telemedicine') {
      // if (selectedbranch?.name) {
      setTelemedicineOpen(true)

      // } else {
      //   alert('Please Select the branch')
      // }
    } else if (title === 'Find Doctor') {
      navigate(selectedbranch?.name ? `${selectedbranch?.name}/doctors` : '/doctors')
    } else if (title === 'Patient Portal') {
      navigate(selectedbranch?.name ? `${selectedbranch?.name}/login` : '/login')
    } else if (item?.url) {
      navigate(item?.url)
    }

  }

  const handleCloseDialog = () => {
    setEmergencyOpen(false)
  }
  const handleUrgentCloseDialog = () => {
    setUrgentCareOpen(false);
  };
  const handleTeleCloseDialog = () => {
    setTelemedicineOpen(false);
  };

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  }
  return (
    <Box
      sx={{
        mt: { xs: "-200px", sm: "0px", md: "0px" },
        p: { xs: "0px 10px 0px 12px", sm: "0px", md: "0px" },
      }}
    >
      <Typography
        // level="h1"

        sx={{ color: "#E54290", textAlign: 'center', cursor: 'pointer', fontWeight: 500, letterSpacing: 0 }}
        fontSize={{ xs: 24, md: 34 }}
      // className={Classes.heading1}
      >
        How Can We Help?
      </Typography>
      <Grid
        container
        // rowSpacing={1}
        columnSpacing={6} rowSpacing={4}
        // spacing={5}
        mt={5}
        justifyContent={{ xs: 'center', sm: 'center', lg: "space-between" }}
        sx={{ p: { xs: "0px", sm: "10px 0px", md: "10px 0px" }, display: { xs: 'flex' } }}
      >
        {CardsData.map((item, index) => (
          <Grid item xs={item?.title === "Patient Portal" ? 12 : 6} sm={6} md={3} lg={2.4} sx={{ p: "5px" }} key={index}>
            <>
              <Card
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  cursor: 'pointer',
                  width: { xs: "100%", md: '100%' },
                  height: { xs: "100%", md: '100%' },
                }}
                className={Classes.card}
                onClick={() => handleSelectOpen(item.title, item)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <CardContent
                  sx={{
                    "&.MuiCardContent-root": {
                      p: "10px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: item?.title === "Patient Portal" ? 'row' : 'column', sm: 'column', md: 'column', lg: 'column'
                      },
                      justifyContent: {
                        xs: item?.title === "Patient Portal" ? 'start' : 'space-between', lg: 'space-between'
                      },
                      // alignContent: "center",

                      alignItems: "center",

                      gap: 1,
                    }
                    }
                  >
                    <div className="Classes.iconsize flex justify-center items-center" style={{ width: '60px', height: '60px' }}>
                      <img
                        src={index === hoveredCard ? item.imgSrchover : item.imgSrc}
                        alt=""
                        // width={35}
                        // height={35}
                        style={{
                          // marginRight: "8px",
                          Height:
                            item.title === "Emergency"
                              ? "60px"
                              : "50px",
                          width:
                            item.title === "Emergency"
                              ? "130%" : (item.title === "Urgent Care" || item.title === "Telemedicine" || (item.title === "Patient Portal" && item.imgSrchover == PortalImg_wt)) ? '45px' : "50px",
                          maxWidth: item.title === "Emergency" && "130%"
                        }}
                      />
                    </div>
                    <Box
                      sx={{
                        height: { sm: "70px", md: "60px" },
                        justifyContent: 'center',
                        textAlign: 'center',
                        mb: {
                          xs: item?.title === "Patient Portal" ? '10px' : '0px', lg: '0px'
                        }
                      }}
                      pt={item.title === "Emergency" ? 2 : 2}
                    >
                      <Typography className={Classes.textcolor}>
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xs: '12px', lg: "14px" }, color: index === hoveredCard ? '#fff' : '#898989' }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </>
            {
              item.title === "Emergency" &&
              <EmergencyPopup open={emergencyOpen} handleClose={handleCloseDialog} />
            }
            {
              item.title === "Urgent Care" &&
              <UrgentCarePopup open={urgentCareOpen} handleClose={handleUrgentCloseDialog} setIsUrgentCareOpen={setUrgentCareOpen} />
            }
            {
              item.title === "Telemedicine" &&
              <TelemedicinePopup open={telemedicineOpen} handleClose={handleTeleCloseDialog} setTelemedicineOpen={setTelemedicineOpen} />
            }
          </Grid>
        ))}
      </Grid>

    </Box >
  );
};

export default HelpComponent;
