import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';

export const Commoncalender = (props) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
            <DatePicker
                sx={{
                    ".MuiOutlinedInput-input":
                    {
                        padding: "9px 14px",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        // border: "none",
                        borderRadius: "7px",
                    },
                    width: props.width,
                }}
                // label="Controlled picker"
                // maxDate={dayjs(new Date())}
                format="DD/MM/YYYY"
                value={props.value}
                onChange={props.onChange}
                maxDate={props.maxDate}
            />
        </DemoContainer>
    </LocalizationProvider>
)

const FormikCommoncalender = ({ field, form, maxDate, width, onKeyDown }) => {
    const handleChange = (date) => {
        form.setFieldValue(field.name, date);
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                        sx={{
                            ".MuiOutlinedInput-input": {
                                padding: "9px 14px",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                borderRadius: '7px',
                            },

                            width: width,

                        }}
                        format="DD/MM/YYYY"
                        value={dayjs(field.value)}
                        onChange={handleChange}
                        maxDate={maxDate}
                        renderInput={(params) => <TextField {...params} error={Boolean(form.errors[field.name] && form.touched[field.name])} onKeyDown={onKeyDown} />}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <ErrorMessage name={field.name}>
                {(msg) => <div style={{ color: '#D32F2F', fontSize: 14 }}>{msg}</div>}
            </ErrorMessage>
        </div>
    );
};

export default FormikCommoncalender;
