import React, { useEffect, useState } from 'react'
import Home from '../components/home/home';
import Footer from '../components/home/footer';
import BranchHome from '../components/branchhome/branchHome';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import DoctorPage from '../components/doctors/page';
import SecondHeader from '../components/header/secondheader';
import { useDispatch, useSelector } from 'react-redux';
import { branchInfo } from '../redux/slices/branchSlice';
import HealthInformationPage from '../components/healthinformation/HealthInformationPage';
import DoctorProfile from '../components/doctors/doctorprofile';
import NoPage from '../components/NoPage';
import AboutUs from '../components/aboutus/AboutUs';
import Condition from '../components/condition/Condition';
import AllPosts from '../components/healthinformation/AllPosts';
import SignUp from '../components/UserSignup/SignUp';
import ConditionDetails from '../components/condition/ConditionDetails';
import SignUpRoute from '../components/UserSignup/SignUpRoute';
import { selectedBranchInfo } from '../redux/slices/SelectedBranchSlice';
import Loginpage from '../components/UserSignup/Loginpage';
import NewUserDetails from '../components/UserSignup/NewUserDetails';
import HealthPortal from '../components/HealthPortal/HealthPortal';
import HealthProfile from '../components/HealthPortal/healthprofile';
import Consultations from '../components/HealthPortal/consultations';
import MedicalRecords from '../components/HealthPortal/medicalrecords';
import Medication from '../components/HealthPortal/medication';
import DiagnosticReports from '../components/HealthPortal/diagnosticreports';
import PaybillOnline from '../components/HealthPortal/paybillonline';
import VerifyingContactDetails from '../components/UserSignup/VerifyingContactDetails';
import SignUpPage from '../components/UserSignup/SignUpPage';
import TestimonalPage from '../components/home/testimonial/TestimonalPage';
import HomeAllPosts from '../components/home/healhinfor/HomeAllPosts';
import ChooseBranch from '../components/header/chooseBranch';
import HealthcareService from '../components/healthcare_services/healthcareservice';
import Healthcaredepart from '../components/healthcare_departs/healthcaredepart';
import SpecialitiesPage from '../components/healthcare_departs/page';
import HealthcareServicePage from '../components/healthcare_services/page';
import ViewArticle from '../components/home/healhinfor/viewArticle';
import ViewAllArticles from '../components/healthinformation/viewAllArticles';
import ViewAllEvents from '../components/healthinformation/viewAllEvents';
import { getBranchLists } from '../redux/actions/loginActions';
import Loading from '../loading';
import PersonDetails from '../components/HealthPortal/PersonDetails';
import ForgotUser from '../components/UserSignup/forgotUser';
import UserService from '../services/UserService';
import Forgotpasswd from '../components/UserSignup/forgotpass';
import FeedPost from '../components/home/healhinfor/feedPost';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';

function RoutesPage() {
  const [selectedLocationName, setSelectedLocationName] = React.useState(null);
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const branchName = useSelector((state) => state.branch.branch);
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
  const [loading, setLoading] = useState(true); // Loading state


  useEffect(() => {
    let locationInfo = localStorage.getItem("brName");
    let selectedbranchInfo = JSON.parse(localStorage.getItem("selectedbranch"));
    if (locationInfo) {
      dispatch(branchInfo(locationInfo))
      dispatch(selectedBranchInfo(selectedbranchInfo))
    }
    setSelectedLocationName(locationInfo);
  }, [selectedLocationName]);

  const mainOrgId = process.env.REACT_APP_PARAMITHA_ID

  useEffect(() => {
    const fetchBranchLists = async () => {
      try {
        await dispatch(getBranchLists(mainOrgId));
      } finally {
        setLoading(false); // Set loading to false after the API call completes
      }
    };

    fetchBranchLists();
  }, [mainOrgId]);



  React.useEffect(() => {
    setSelectedLocationName(branchName);
  }, [branchName])
  // console.log(selectBranchInfo, 66)

  // if (loading) {
  //   return <div>Loading...</div>; // Replace with your actual preloader component
  // }
  // const token = UserService.getToken()
  // console.log(token, "token")
  return (
    <Box>
      <Helmet>
        <>
          <title>Paramitha Hospitals</title>
          <meta property="og:image"
            content="https://paramith-website-contents.s3.ap-south-1.amazonaws.com/876545.png" />
        </>
      </Helmet>
      {loading && <Loading />}

      {(pathname !== "/" || branchName) &&
        <>
          <SecondHeader />
        </>
      }
      {/* <Box sx={{ maxHeight: '400px' }}> */}
      <Routes>
        {/* <Route path="/" element={branchName ? <BranchHome /> : <Home />} /> */}
        <Route
          path="/"
          element={branchName ? <Navigate to={`/${branchName}`} /> : <Home />}
        />
        <Route path="/:branch/testimonial" element={<TestimonalPage />} />

        {/* <Route path="/:branchName" element={<BranchHome />} /> */}
        <Route path={`/${branchName ? branchName : ''}`} element={<BranchHome />} />
        <Route path={`/${branchName ? branchName : ''}`}>
          <Route path="signup2" element={<SignUpRoute />} />
          <Route path="login" element={<Loginpage />} />
          <Route path="newuser" element={<NewUserDetails />} />
          <Route path="verifycontact" element={<VerifyingContactDetails />} />

          {/* <Route path="signup" element={<SignUp />} /> */}
          <Route path="signup" element={<SignUpPage />} />
          <Route path="forgotuser" element={<ForgotUser />} />
          <Route path="forgotpassword" element={<Forgotpasswd />} />

          <Route path="doctors" element={<DoctorPage />} />
          <Route path="doctor/:id" replace element={<DoctorProfile />} />

          <Route path="healthcaredept" element={<SpecialitiesPage />} />
          <Route path="healthcaredept/:name/:id" element={<Healthcaredepart />} />

          <Route path="healthcareservices" element={<HealthcareServicePage />} />
          <Route path="healthcareservices/:name/:id" element={<HealthcareService />} />

          <Route path="healthinformation" element={<HealthInformationPage />} />
          {/* <Route path="viewarticle" element={<ViewArticle />} /> */}
          <Route path="viewarticle/:id" element={<ViewArticle />} />

          <Route path="conditions" element={<Condition />} />
          <Route path="condition/:id/:name" element={<ConditionDetails />} />
          <Route path="privacy" element={<HealthInformationPage />} />
          <Route path="AboutUs" element={<AboutUs />} />
          <Route path="allposts" element={<AllPosts />} />
          <Route path="articles" element={<ViewAllArticles />} />
          <Route path="events" element={<ViewAllEvents />} />
          <Route path="homeposts" element={<HomeAllPosts />} />
          <Route path="post/:id" element={<FeedPost />} />

          <Route path="privacy" element={<HealthInformationPage />} />

          < Route path="healthportal" element={<HealthPortal />} />
          < Route path="patient" element={<PersonDetails />} >
            <Route index element={<HealthProfile />} />
            <Route path="healthprofile" element={<HealthProfile />} />
            <Route path="consultations" element={<Consultations />} />
            <Route path="medicalrecords" element={<MedicalRecords />} />
            <Route path="medication" element={<Medication />} />
            <Route path="diagnostic" element={<DiagnosticReports />} />
            <Route path="paybill" element={<PaybillOnline />} />
          </Route>
        </Route>
        <Route path="*" element={<NoPage />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
      {/* </Box> */}
      <Footer />
    </Box>
  );
}

export default RoutesPage;
