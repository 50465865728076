import React, { useEffect, useState } from 'react'
import { Grid, Paper, Box, Typography, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import BranchComponent from '../BranchComponent';
import { SpecialistTab } from '../../shared/constants';
import healthDepartIcon from '../../assets/healthcareprograms/Health Department 2.svg'
import axios from 'axios';
import URL from '../../services/api_url';
import { useNavigate } from 'react-router-dom';
import BranchUrl from '../../services/api_branch_url';

const SpecialitiesPage = () => {
    const branchName = useSelector((state) => state.branch.branch);
    const [departlistInfo, setDepartlistInfo] = React.useState([]);
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const urls = BranchUrl()
    useEffect(() => {
        if (branchName) {
            setLoading(true)
            axios.get(`${urls.fhir_tenant_orgId}Organization?_count=200&type=dept`, {
                headers: {
                    'Realm': 'parimitha',
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                }
            }).then((res) => {
                // let sortList = res.data?.entry ? res.data?.entry.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system === "https://positionofdepartments/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system === "https://positionofdepartments/")?.[0]?.value) : []
                let sortList = res.data.entry.sort((a, b) =>
                    (a.resource.identifier?.find(id => id.system === 'https://positionofdepartments/')?.value || '999')
                        .localeCompare(b.resource.identifier?.find(id => id.system === 'https://positionofdepartments/')?.value || '999')
                );
                setDepartlistInfo(sortList || [])
            }).catch((err) => console.log(err))
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [selectBranchInfo])

    const handleSelectService = (title, id) => {
        let name = title
        navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/healthcaredept/${name.replace(/[\s,]+/g, '')}/${id}` : `/healthcaredept/${name.replace(/[\s,]+/g, '')}/${id}`)
    }

    return (
        <Box sx={{ p: { xs: '0px 10px', md: '0px 50px', lg: '0px 70px' } }} >
            <Grid className='px-8' style={{ minHeight: '500px' }}>
                <div style={{ color: '#E54290' }} className='text-xl sm:text-xl md:text-2xl lg:text-3xl font-semibold text-center pt-4 '>Healthcare Departments
                    {/* & Services */}
                    @
                    {branchName ? `${branchName}` : 'Paramitha Hospitals'}</div>
                <div className='mt-1'>

                    <Grid container spacing={2} pt={{ xs: 0, md: 1 }}>
                        {!branchName &&
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <BranchComponent departlistInfo={departlistInfo} />
                            </Grid>
                        }
                        <Grid item container xs={branchName ? 12 : 12} sm={branchName ? 12 : 12} md={branchName ? 12 : 8} lg={branchName ? 12 : 9} columnSpacing={6} rowSpacing={4} mt={{ xs: 0, md: 0 }} >
                            {loading ? (
                                <Grid item xs={12} className='text-center'>
                                    <CircularProgress />
                                    <Typography variant="h6" color="textSecondary">
                                        Loading...
                                    </Typography>
                                </Grid>
                            ) :
                                departlistInfo?.length > 0 ?
                                    departlistInfo?.map((val, index) => {
                                        const imgSrc = val?.resource?.identifier?.filter((val) => val?.system === `${process.env.REACT_APP_API_Docsteth}logo`)?.[0]?.value;                                        // const imgSrc = val?.resource?.identifier?.[0]?.value

                                        // // Check if imgSrc is a valid string and not a number
                                        // const isValidImage = typeof imgSrc === 'string' && imgSrc.trim() !== '' && isNaN(imgSrc);
                                        // const imgSrc = val?.resource?.identifier?.filter((item) => item?.system === 'https://dev.api.docsteth.com/api/api/v1/logo')
                                        // console.log(imgSrc, 37)
                                        return (
                                            <Grid item xs={12} sm={6} md={3} key={index} >
                                                <Box className='flex items-center cursor-pointer px-2'
                                                    sx={{
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        cursor: "pointer",
                                                        textAlign: 'center',
                                                        mt: 1,
                                                        opacity: 1,
                                                        p: "25px 10px",
                                                        backgroundColor: '#FDF3F7',
                                                        height: '160px',
                                                        border: '0.5px solid #E54290',
                                                        borderRadius: '12px',
                                                        color: "#1D5775",

                                                        width: "100%", '&:hover': {
                                                            backgroundColor: '#E54290',
                                                            color: '#FFFFFF'
                                                        }
                                                    }}
                                                    onClick={() => handleSelectService(val?.resource?.name, val?.resource?.id)}
                                                >
                                                    <img
                                                        src={imgSrc || healthDepartIcon}
                                                        alt="healthdepart"
                                                        style={{ width: '54px', height: '54px', marginRight: '8px' }}
                                                    />
                                                    <Box className="truncate" sx={{ width: "100%" }}>
                                                        <Typography noWrap sx={{
                                                            fontSize: { xs: "14px", md: '14px', lg: '16px' },
                                                            fontWeight: "500",
                                                            textTransform: 'capitalize',
                                                            opacity: "100%",
                                                            // overflow: "hidden",
                                                            // whiteSpace: "nowrap",
                                                            // textOverflow: "ellipsis",
                                                        }}>
                                                            {val?.resource?.name}
                                                        </Typography>
                                                        {/* <Typography noWrap sx={{
                                                            color: "#000000",
                                                            fontSize: {
                                                                xs: "12px", md: '11px', lg: '12px'
                                                            },
                                                            opacity: "100%",
                                                            // overflow: "hidden",
                                                            // whiteSpace: "nowrap",
                                                            // textOverflow: "ellipsis",
                                                        }}>
                                                            {val?.resource?.alias}
                                                        </Typography> */}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        );
                                    })
                                    :
                                    <Grid item xs={12} sm={12} md={9}
                                        className=' text-lg md:text-2xl text-center w-full '>{branchName ? <div>No Data Available</div> : <div>Please Select Branch / Facility</div>}</Grid>
                            }

                        </Grid>

                    </Grid>
                </div>
            </Grid>
        </Box >
    )
}

export default SpecialitiesPage;