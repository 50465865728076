"use client";
import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import hospitalLight from '../../../assets/home-images/HealthDepartment-Light.svg';
import hospitalBlue from '../../../assets/home-images/HealthDepartment.svg';
import specialityLight from '../../../assets/home-images/Medical Speciality-light.svg';
import specialityBlue from '../../../assets/home-images/Medical Speciality.svg';
import doctorLight from '../../../assets/home-images/doctor-lIGHT.svg';
import doctorBlue from '../../../assets/home-images/doctor-blue.svg';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SpecialistsTab from "./SpecialistsTab";
import DoctorTab from "./DoctorTab";
import HealthTab from "./HealthTab";
import { locationJson } from "../../../shared/constants";
import { FmdGood } from "@mui/icons-material";
import { useSelector } from "react-redux";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 1, md: 0 }, pt: { xs: 2, md: 0 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HealthServices = ({ ...props }) => {
  // const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState("Specialists");
  const branchList = useSelector((state) => state.login.branchLists);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const [value, setValue] = React.useState(1);

  const [branch, setBranch] = React.useState(null);

  const [inputValue, setInputValue] = React.useState("");

  const [selectTab, setSelectTab] = useState('Medical Specialities')

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // If branchList is updated and branch is not set, set it to Kothapet if available
    if (branchList.length > 0 && branch === null) {
      const defaultBranch = branchList.find(item => item.name.includes('Kothapet'));
      if (defaultBranch) {
        setBranch(defaultBranch);
      }
    }
  }, [branchList, branch]);
  const sanitizedLocation = {
    ...branch,
    name: branch?.name?.replace(/[\s,]+/g, '').toLowerCase(),
  };

  return (
    <Box
      sx={{
        mt: "25px",
        p: { xs: "0px 10px 0px 10px", sm: "0px", md: "0px" },
      }}
    >
      <Typography
        level="h1"
        sx={{
          textAlign: "center",
          color: "#E54290",
          fontWeight: 500,
          fontSize: { xs: 24, md: 34 },
          letterSpacing: 0
        }}
      >
        Healthcare services
      </Typography>
      <Typography
        level="h2"
        fontSize={18}
        sx={{
          textAlign: "center",
          //   fontWeight: "bold",
          color: "#000000",
          opacity: "1",
          textTransform: "capitalize",
          fontSize: { xs: 16, md: 18 },
          fontWeight: 'lighter'
        }}
      >
        Experience Holistic patient-Centric Care For Women & Children
      </Typography>

      <Box
        sx={{
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          width: "100%",
        }}
        pt={2}
      >
        <Box
          sx={{
            maxWidth: { md: "100%" },
            minWidth: { md: "100%" },
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"  // or "secondary"
            textColor="primary"
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            mx={2}
            // className={classes.tabs}
            sx={{
              margin: { sm: '0px 0px', md: '0px 0px' },
              ".MuiTabs-flexContainer": {
                display: 'flex !important',
                justifyContent: 'space-between !important'
              },
              background: "#FDF3F7",
              borderRadius: "12px 12px 0px 0px",
              textTransform: 'capitalize !important',
              '& .MuiTabs-indicator': {
                display: 'none',
              },
              "&.MuiTabs-root": {
                minHeight: "71px !important",


                // width: "33.3% !important",
              },

            }}
          >
            <Tab
              label="Hospital Departments"
              {...a11yProps(0)}
              icon={
                <img
                  src={value === 0 ? hospitalLight : hospitalBlue}
                  alt={"alt"}
                  width={24}
                  height={24}
                />
              }
              iconPosition="start"
              sx={{
                fontSize: { md: '21px', sm: '12px', xs: '12px' },  // Default font size for medium and larger screens

                "&.MuiTab-root": {
                  minHeight: "71px !important",
                  width: "33.3% !important",
                  textTransform: 'capitalize !important',
                  fontSize: { md: '21px', sm: '12px', xs: '12px' },  // Default font size for medium and larger screens
                  display: { xs: 'flex', sm: 'flex' }, flexDirection: { xs: 'column', sm: 'column', md: 'row' }
                },

                "&.MuiTab-root.Mui-selected": {
                  background: "#E54290",
                  color: "#fff",
                  borderRadius: "12px 12px 0px 0px",
                  border: '1px solid #155774'
                },

              }}
            />

            <Tab
              label="Medical Specialities"
              {...a11yProps(1)}
              // className={classes.tabheader}
              icon={
                <img src={value === 1 ? specialityLight : specialityBlue} alt={"alt"} width={24} height={24} />
              }
              iconPosition="start"
              sx={{
                fontSize: { md: '21px', sm: '12px', xs: '12px' },  // Default font size for medium and larger screens

                "&.MuiTab-root": {
                  minHeight: "71px !important",
                  width: "33.3% !important",
                  textTransform: 'capitalize !important',
                  fontSize: { md: '21px', sm: '12px', xs: '12px' },  // Default font size for medium and larger screens
                  display: { xs: 'flex', sm: 'flex' }, flexDirection: { xs: 'column', sm: 'column', md: 'row' }
                },

                "&.MuiTab-root.Mui-selected": {
                  background: "#E54290",
                  color: "#fff",
                  borderRadius: "12px 12px 0px 0px",
                  border: '1px solid #155774'
                },

              }}
            />
            <Tab
              label="Our Doctors"
              {...a11yProps(2)}
              // className={classes.tabheader}
              icon={
                <img src={value === 2 ? doctorLight : doctorBlue} alt={"alt"} width={24} height={24} />
              }
              iconPosition="start"
              // sx={{ color: value === 2 ? "#FFFFFF" : "#155774", }}
              sx={{
                fontSize: { md: '21px', sm: '12px', xs: '12px' },  // Default font size for medium and larger screens

                "&.MuiTab-root": {
                  minHeight: "71px !important",
                  width: "33.3% !important",
                  textTransform: 'capitalize !important',
                  fontSize: { md: '21px', sm: '12px', xs: '12px' },  // Default font size for medium and larger screens
                  display: { xs: 'flex', sm: 'flex' }, flexDirection: { xs: 'column', sm: 'column', md: 'row' }
                },

                "&.MuiTab-root.Mui-selected": {
                  background: "#E54290",
                  color: "#fff",
                  borderRadius: "12px 12px 0px 0px",
                  border: '1px solid #155774'
                },

              }}

            />
          </Tabs>
        </Box>
        <Grid container spacing={1} mt={1}>
          <Grid item xs={12} sm={6} md={4} sx={{ p: { xs: "10px 0px", sm: "10px", md: '0px' } }} >

            <Box sx={{ mt: 2 }}>
              <Autocomplete
                value={branch}
                onChange={(event, newValue) => {
                  setBranch(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={branchList}
                getOptionLabel={(option) => option?.name || ''}
                // options={options}
                // sx={{ width: 300 }}
                sx={{
                  width: "100%",
                  // border: "1px solid red",
                  // borderRadius: "5px",
                  ".MuiAutocomplete-inputRoot": { borderRadius: '8px', backgroundColor: '#FDF3F7', borderColor: '#E54290' },
                  ".MuiOutlinedInput-root": { padding: "0px" },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="All Facilities"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <IconButton>
                          <FmdGood sx={{ fontSize: "15px", color: '#E54290' }} />
                        </IconButton>
                      ),
                    }}
                    sx={
                      {
                        ".MuiOutlinedInput-notchedOutline": {
                          // height: "50px",
                          border: "1px solid #E54290"
                        },
                        ".MuiInputBase-root": {
                          backgroundColor: '#FDF3F7',
                        },
                      }
                    }
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>


        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            p: {
              xs: "0px",
              sm: "0px 20px 0px 0px",
            },
          }}
        > */}
        <CustomTabPanel
          value={value}
          index={0}
          sx={{ p: { xs: "0px", sm: "10px 0px", md: "10px 0px" } }}
        >
          <HealthTab branch={sanitizedLocation} />
        </CustomTabPanel>
        {/* </Grid> */}
        {/* <Grid
            item
            xs={12}
            sm={12}
            md={12}
          // sx={{ p: { xs: "0px", sm: "0px 10px 0px 10px" } }}
          > */}
        <CustomTabPanel value={value} index={1}>
          <SpecialistsTab branch={sanitizedLocation} />
        </CustomTabPanel>
        {/* </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ p: { xs: "0px", sm: "0px 10px 0px 10px" } }}
        > */}
        <CustomTabPanel value={value} index={2} >
          <DoctorTab branch={sanitizedLocation} />
        </CustomTabPanel>
        {/* </Grid> */}
      </Box>
    </Box>
  );
};

export default HealthServices;
