import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, Autocomplete, MenuItem } from "@mui/material"
import { CustomTextField } from '../atoms/TextField';
import { OutlinedButton } from '../atoms/commonbutton';


const VerifyingContactDetails = ({ updateStep }) => {
  const serviceOptions = ["Mobile", "Email",];
  const [verifyMethod, setVerifyMethod] = React.useState(null);
  const [inputIntialValue, setInputIntialValue] = React.useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifyOtp, setVerifyotp] = useState("");

  const handleChangePhoneNumber = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    setPhoneNumber(inputValue.slice(0, 10));
  }
  const handleChangeOtp = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    setVerifyotp(inputValue.slice(0, 6));



  }
  const handleClickOtp = () => {
    if (phoneNumber.length === 10 && verifyOtp.length == 6) {

    } else {
    }


  }








  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5} md={5} >
          <Box sx={{ padding: "10px 40px" }} >
            <Box sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, fontWeight: 600, color: "#1B5775" }}>
                Step 2</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, fontWeight: 600, color: "#000000" }}>
                Verify your Contact details</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 400, color: "#000000" }}>
                Verify your Profile with Phone number or Email ID provided During your registration</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 500 }}>
                Phone Number</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <CustomTextField
                // label="Display name"
                placeholder="Enter your Phone Number"
                type={'text'}
                borderColor="#59D8FA"
                borderRadius="7px"
                // name="fullName" 
                value={phoneNumber}
                onChange={handleChangePhoneNumber}
              />
              {/* <Autocomplete
                value={verifyMethod}
                onChange={(event, newValue) => {
                  setVerifyMethod(newValue);
                }}
                inputValue={inputIntialValue}
                onInputChange={(event, newInputValue) => {
                  setInputIntialValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={serviceOptions}
                sx={{
                  width: "100%",
                  "& .MuiAutocomplete-inputRoot": { height: 43 },
                  ".MuiOutlinedInput-root": { padding: "0px" },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Verification Method"
                    InputProps={{
                      ...params.InputProps,
                      // startAdornment: (
                      //   <IconButton>
                      //     <FmdGoodIcon sx={{ fontSize: "15px" }} />
                      //   </IconButton>
                      // ),
                    }}
                  />
                )}
              /> */}
              {/* <TextField
                id="outlined-select-currency"
                select
                size='small'
                // label="Select"
                // defaultValue="EUR"
                // helperText="Please select your currency"
                fullWidth
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: '7px',
                    // backgroundColor: 'whitesmoke',
                  },

                  '& .MuiOutlinedInput-root': {
                    // padding: "2px 10px !important",

                    '&.Mui-focused fieldset': {
                      borderColor: '#59D8FA',
                    },
                  },
                }}
              >
                {serviceOptions.map((option, id) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField> */}
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 500 }}>
                Verify OTP</Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <CustomTextField
                // label="Display name"
                placeholder="Enter OTP Here"
                type={'text'}
                borderColor="#59D8FA"
                borderRadius="7px"
                // name="fullName" 
                value={verifyOtp}
                onChange={handleChangeOtp}
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div>
                <OutlinedButton
                  width="120px"
                  height="32px"
                  borderColor="#1B5775"
                  text="Verify OTP"
                  onClick={handleClickOtp} // onClick event handler
                  disabled={!phoneNumber || !verifyOtp}
                  color="#1B5775"
                  borderRadius="7px"
                  startIcon={null}
                  endIcon={null} />
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={6} >
          <Box sx={{ padding: "10px 20px" }} ></Box>
        </Grid>
      </Grid>
    </Box >
  )
}

export default VerifyingContactDetails
