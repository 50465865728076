import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHotkeys } from "react-hotkeys-hook";
import EmojiPicker from 'emoji-picker-react';
import { BsEmojiSmile } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import './chat.css';
import { Button, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
// import { Message } from "./components/Message";
import axios from "axios";
import { Message } from "./message";
// import ChatBg from '../assets/chatbg.png';

export function ActiveChat({ sendJsonMessage, messageHistory }) {
    const { conversationParam } = useParams();
    const [participants, setParticipants] = useState([]);
    const [conversation, setConversation] = useState(null);
    // const [messageHistory, setMessageHistory] = useState([]);
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(2);
    const [hasMoreMessages, setHasMoreMessages] = useState(false);
    const [meTyping, setMeTyping] = useState(false);
    const [typing, setTyping] = useState(false);
    const [emojiOpen, setEmojiOpen] = useState(false);
    const [storeDate, setStoreDate] = useState([]);


    // function updateTyping(event) {
    //   if (event.user !== user?.userid) {
    //     setTyping(event.typing);
    //   }
    // }

    //   const { readyState, sendJsonMessage } = useWebSocket(
    //    `ws://13.235.100.97/api/getconversation/${localStorage?.getItem('visitorId')}/1425645344`,
    //     {
    //       onOpen: () => {
    //         console.log("Connected!");
    //       },
    //       onClose: () => {
    //         console.log("Disconnected!");
    //       },
    //       onMessage: (e) => {
    //         const data = JSON.parse(e.data);
    //         setMessageHistory(data)
    //       },
    //     }
    //   );

    //   const connectionStatus = {
    //     [ReadyState.CONNECTING]: "Connecting",
    //     [ReadyState.OPEN]: "Open",
    //     [ReadyState.CLOSING]: "Closing",
    //     [ReadyState.CLOSED]: "Closed",
    //     [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    //   }[readyState];

    //   useEffect(() => {
    //     if (connectionStatus === "Open") {
    //       sendJsonMessage({ type: "read_messages" });
    //     }
    // }, [connectionStatus, sendJsonMessage]);
    useEffect(() => {
        // if(localStorage?.getItem('roomId')){
        // fetchMessages()
        // }
    }, [])

    //   async function fetchMessages() {
    //     const apiRes = await fetch(
    //       `http://13.235.100.97/api/getconversation/${localStorage?.getItem('visitorId')}/1425645344`,
    //       {
    //         method: "GET",
    //       }
    //     );
    //     console.log(apiRes.json(), 85)
    //     if (apiRes.status === 200) {
    //       const data = await apiRes.json();
    //       setHasMoreMessages(data.next !== null);
    //       setPage(page + 1);
    //       setMessageHistory((prev) => prev.concat(data.results));
    //     }
    //   }

    // const fetchMessages = () => {
    //   axios.get(`http://13.235.100.97/api/getRoomConversations/${localStorage?.getItem('roomId')}`).then((res) =>{
    //     setMessageHistory(res?.data)
    //   }).catch((err) => console.log(err))
    // }

    const timeout = useRef();

    function timeoutFunction() {
        setMeTyping(false);
        // sendJsonMessage({ type: "typing", typing: false });
    }

    function onType() {
        // if (!meTyping) {
        //   setMeTyping(true);
        //   sendJsonMessage({ type: "typing", typing: true });
        //   timeout.current = setTimeout(timeoutFunction, 5000);
        // } else {
        //   clearTimeout(timeout.current);
        //   timeout.current = setTimeout(timeoutFunction, 5000);
        // }
    }

    function handleChangeMessage(e) {
        setMessage(e.target.value);
        onType();
    }

    useEffect(() => () => clearTimeout(timeout.current), []);

    const handleSubmit = () => {
        if (message.length === 0 || message.length > 512) return;
        // sendJsonMessage({
        //   type: "chat_message",
        //   message,
        //   "receiver_id":"1425645344",
        // });
        sendJsonMessage({
            "roomid": localStorage.getItem('roomId'),
            "content": message,
            "attachment": "",
            "urls": "",
            "subject": "help"
        })

        setMessage("");
        clearTimeout(timeout.current);
        timeoutFunction();
    };

    const inputReference = useHotkeys(
        "enter",
        () => {
            handleSubmit();
        },
        {
            enableOnTags: ["TEXTAREA"],
        }
    );

    useEffect(() => {
        if (inputReference.current) {
            (inputReference.current).focus();
        }
    }, [inputReference]);

    const chatEmoji = () => {
        setEmojiOpen(!emojiOpen);
    }

    const emojiClose = () => {
        setEmojiOpen(false);
    }

    const emojiSetFunc = (e) => {
        setMessage(message + e.emoji);
    }

    useEffect(() => {
        if (messageHistory?.length > 0) {
            const uniqueDates = [...new Set(messageHistory?.map(msg => formatMessageDate(msg?.timestamp)))];
            setStoreDate(uniqueDates);
        }
    }, [messageHistory]);


    function formatMessageDate(timestamp) {
        return new Date(timestamp).toLocaleString("en-US", { month: "short", day: "2-digit", year: "numeric" });
    }

    const sortedMessages = [...messageHistory].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    return (
        <div className=" flex-col" style={{ background: "#fdf3f7" }}>
            <div
                id="scrollableDiv"
                style={{ height: `${window.innerHeight - 225}px`, backgroundSize: "cover" }}
                className="flex flex-col-reverse relative w-full px-2 overflow-y-auto"
            >
                <InfiniteScroll
                    dataLength={messageHistory.length}
                    // next={fetchMessages}
                    className="flex"
                    style={{ flexDirection: 'column' }}
                    inverse={true}
                    hasMore={hasMoreMessages}
                    loader={<CircularProgress className="flex self-center" />}
                    scrollableTarget="scrollableDiv"
                >
                    {storeDate?.map((date) => (
                        <div key={date} className="text-center text-gray-500 my-2">{date}</div>
                    ))}
                    {sortedMessages?.map((msg) => (
                        <Message key={msg?.id} message={msg} />
                    ))}
                </InfiniteScroll>
            </div>

            {typing && <p className="text-gray-500 text-sm text-center py-1">Typing...</p>}

            {emojiOpen && (
                <div className="absolute bottom-16 left-0 right-0 bg-white p-2 shadow-lg">
                    <EmojiPicker onEmojiClick={emojiSetFunc} />
                </div>
            )}

            <div className="flex items-center p-2 border-t border-gray-200 bg-white">
                <span onClick={chatEmoji} className="p-2">
                    <BsEmojiSmile />
                </span>
                <textarea
                    style={{ overflow: 'hidden' }}
                    value={message}
                    onChange={handleChangeMessage}
                    ref={inputReference}
                    className="w-full border-none p-2 rounded-md resize-none"
                    rows={1}
                    placeholder="Type a message"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit()
                        }
                    }} />
                <Button onClick={handleSubmit} className="text-blue-500 capitalize" style={{ textTransform: 'capitalize' }}>
                    Send
                </Button>
            </div>
        </div>
    );
}
