import React, { useState } from "react";
import { Box, CardMedia, Button, Typography } from "@mui/material";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { OutlinedButton } from "../../atoms/commonbutton";

const FeedEvent = ({ docFeed }) => {

    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        // setExpanded(true);
    };

    return (
        <Box
            sx={{
                // border: "1px solid lightgrey",
                color: "black",
                // margin: "10px",
                padding: '0px 5px',
                // marginBottom: "10px",
                // height: "100%",
                position: 'relative'
            }}
        >
            <div>
                <Typography
                    component={"p"}
                    sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: expanded ? "none" : 2, // Shows 3 lines when collapsed
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: '14px',
                        lineHeight: 1.8,
                        wordBreak: 'break-word', // Prevents horizontal overflow by breaking long words
                        mt: 1
                    }}
                >
                    {docFeed?.description}
                    {/* ihehufeefhdhfjdssjfdssfdsjfdjsfrjirefiewidefsjssfdjsfdsfdrltreiherhewlrewhrewwlhereegjnfdsjnkfdjnfdjncvmn,vcm,cvmnvcnmvcmnfjkfsdkjfdjkvfnxn,fdm,fgj,fdgnjdfnfdfjdsjf.ksdjkshfbhfjerweoidjwischdbjcvbdfjghrejdkfcndkjxmfncnreukdjfnc sdjxmfncnkdsjxfncnneruidjkxfncmsdxjscnndkxjkhfckjdxmdfncdiukxjfnckcdsuxkjfbedsfhcjnemdsfkejd
                    ihehufeefhdhfjdssjfdssfdsjfdjsfrjirefiewidefsjssfdjsfdsfdrltreiherhewlrewhrewwlhereegjnfdsjnkfdjnfdjncvmn,vcm,cvmnvcnmvcmnfjkfsdkjfdjkvfnxn,fdm,fgj,fdgnjdfnfdfjdsjf.ksdjkshfbhfjerweoidjwischdbjcvbdfjghrejdkfcndkjxmfncnreukdjfnc sdjxmfncnkdsjxfncnneruidjkxfncmsdxjscnndkxjkhfckjdxmdfncdiukxjfnckcdsuxkjfbedsfhcjnemdsfkejd */}
                </Typography>
                {docFeed?.description?.length > 80 ? (

                    <Typography
                        component={"span"}
                        onClick={() => setExpanded(!expanded)}
                        sx={{ cursor: "pointer", fontSize: 14, color: "blue" }}
                    >
                        {expanded ? "Read Less" : "Read More"}
                    </Typography>
                ) : (
                    ""
                )}

            </div>
            <div

            >
                {/* <Typography sx={{ p: "0px 8px", fontSize: 13, height: 62 }}>

                    {docFeed?.description ? (
                        <>
                            {expanded
                                ? docFeed.description
                                : `${docFeed.description.slice(0, 75)}`}
                            {docFeed.description.length > 80 ? (
                                <Typography
                                    component={"span"}
                                    onClick={handleExpandClick}
                                    sx={{ cursor: "pointer", fontSize: 14, ml: 1, color: '#193BAA' }}
                                >
                                    {expanded ? "Read Less" : "Read More"}{" "}
                                </Typography>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <Box></Box>
                    )}
                </Typography> */}
            </div>

            {/* <div style={{
                    position: 'absolute', backgroundColor: "#F6F6F7",
                    bottom: 0, left: "5px", right: "5px", padding: "10px 20px",
                    opacity: 0.9,

                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                        className="d-flex cursor"
                    >
                        <div style={{ color: '#1D5775', fontWeight: 600, fontSize: 14 }}>Event</div>
                        <OutlinedButton text="Register" borderRadius="20px" height="25px" fontSize="13px" borderColor="#1D5775" color="#1D5775" />
                    </div>
                    <div className="fntsz-13 mt-0.5 font-medium">Free Health camp for Family Health, Woman and child health</div>
                </div> */}
            {/* </div> */}
        </Box>
    );
};

export default FeedEvent;
