import { Box, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { Call, Directions, Email, Place, VerifiedUser } from "@mui/icons-material";
import ContainedButton from "../atoms/commonbutton";

const FacilityInformation = ({ orgData, qr, orgInfo }) => {
  console.log(orgInfo, "orgdata")
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
  const [mapCenter, setMapCenter] = useState({ lat: "", lng: "" });
  const [loc, setLoc] = useState({ lat: "", lng: "" });
  const locations = [
    ["chanda nagar", 17.495824645394702, 78.32095001501918],
    ["chintal", 17.487892061502862, 78.44854428029457],
    ["kompally", 17.518422339582578, 78.48276319462754],
    ["kothapet", 17.359816366215018, 78.54491009787584],
    ["medipally", 17.40790559980765, 78.59811972286937]
  ];
  const latitude = parseFloat(orgData?.position?.latitude);
  const longitude = parseFloat(orgData?.position?.longitude);
  const branchName = useSelector((state) => state.branch.branch);
  const [locationBranch, setLocationBranch] = React.useState('');
  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: latitude,
    lng: longitude
  };

  useEffect(() => {
    setLocationBranch(branchName)
  }, [])

  const handleChange = (event) => {
    setLocationBranch(event.target.value);
  };

  useEffect(() => {
    // Update map center and location when orgData changes
    if (orgData && orgData.position) {
      const latitude = parseFloat(orgData.position.latitude);
      const longitude = parseFloat(orgData.position.longitude);
      if (!isNaN(latitude) && !isNaN(longitude)) {
        setLoc({ lat: latitude, lng: longitude });
        setMapCenter({ lat: latitude, lng: longitude });
      }
    }
  }, [orgData]);

  // console.log(orgData?.position?.latitude, orgData)
  // console.log(orgInfo, "orginfo")

  const handleCoords = (t) => {
    // if (adjust) {
    setLoc({ lat: t.lat, lng: t.lng });
    setMapCenter({ lat: t.lat, lng: t.lng });
    // }
  };



  return (
    <div >
      <Box sx={{ mt: 12 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Grid item container spacing={2}>
              <Grid item sm={8} md={8} >
                <Box sx={{ display: 'flex', color: '#2E3B43', fontSize: 20, fontWeight: 500, alignItems: 'center', gap: 1 }}>
                  <VerifiedUser sx={{ color: '#3CB90E' }} />
                  <div>Verified Healthcare Provider</div>

                </Box>
                <div className="mt-4">
                  <span style={{ color: '#2E3B43', fontSize: 15, fontWeight: 500 }}> HFR ID : </span>
                  {
                    orgInfo?.identifier?.find(id => id.system === "https://hfrId/")?.value || '-'
                  }
                  <span>

                  </span>
                </div>
                <p className="text-sm mt-1 font-normal" style={{ color: '#4D4D4D' }}>Scan the QR code to find this Doctor in other Apps & Hospital Website</p>

                <div className="mt-6 text-xl font-medium" style={{ color: '#2E3B43' }}>
                  Contact Details
                </div>
                <div className="mt-4 font-normal" style={{ color: '#4D4D4D' }}>
                  {selectedbranch?.name}
                </div>
                <div className="flex items-center font-normal gap-2 mt-2" style={{ color: '#4D4D4D' }}>
                  <Call />
                  <div className="text-sm font-normal">{orgInfo?.telecom?.[0]?.value || "+91 040 2223 09888"}</div>
                </div>
                <div className="flex items-center font-normal gap-2 mt-4" style={{ color: '#4D4D4D' }}>
                  <Email />
                  <div className="text-sm font-normal">{orgInfo?.telecom?.[1]?.value || "support@paramithahospitals.com"}</div>
                </div>
                <div className="flex items-center  gap-2 mt-4" style={{ color: '#4D4D4D' }}>
                  <Place />
                  <div className="text-sm font-normal pt-4 text-wrap ">
                    {orgData?.address?.line?.[0] ? `${orgData?.address?.line?.[0]}, ` : ''}
                    {orgData?.address?.district ? `${orgData?.address?.district}, ` : ''}
                    {orgData?.address?.state ? `${orgData?.address?.state}, ` : ''}
                    {orgData?.address?.postalCode ? `${orgData?.address?.postalCode},` : ''}
                  </div>
                </div>
              </Grid>
              <Grid item sm={4} md={4}>
                {/* <Box style={{ width: '100px', height: '100px' }} className="border border-inherit">{
                // qr && <QRcode id="myqr" value={qr} size={100} />
              }</Box> */}
              </Grid>
            </Grid>


            {/* <Divider sx={{ mt: 4, mr: '50px' }} /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card>
              {/* <Box className="flex justify-between items-center">

              <div className="text-xs"><Card className="px-4 py-2 ">{selectedbranch?.name}</Card></div>

              <div className="text-xs cursor-pointer"><Card className="px-2 py-2">
                <a
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  href={`https://www.google.com/maps?q=${mapCenter?.lat},${mapCenter?.lng}`}
                >
                  Get Directions

                </a>
              </Card></div>
            </Box> */}
              <Box >
                {
                  mapCenter &&
                  <LoadScript googleMapsApiKey={process.env.REACT_APP_LOCATION_KEY}>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={mapCenter}
                      zoom={20}
                    >
                      <Marker position={mapCenter} />
                    </GoogleMap>
                  </LoadScript>
                }
              </Box>

            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6}>
            {/* <div className="text-center mt-1 font-normal" style={{ color: '#4D4D4D' }}>Connect on social media</div> */}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <div className="flex justify-between items-center md:justify-center gap-2 mt-1">
              {/* <FaXTwitter style={{ width: 24, height: 24, color: '#E44190' }} />
              <CiInstagram style={{ width: 24, height: 24, color: '#E44190' }} />
              <RiYoutubeLine style={{ width: 24, height: 24, color: '#E44190' }} />
              <AiOutlineFacebook style={{ width: 24, height: 24, color: '#E44190' }} />
              <FaLinkedin style={{ height: 24, width: 24, color: '#E44190' }} /> */}

            </div>
          </Grid>
          <Grid item xs={12} md={6} >
            <div className="flex  md:flex-row items-center gap-3 md:justify-end mt-1 md:m-0">
              {/* <OutlinedButton text=" Write a Review" width={{ xs: '170px', md: "200px", lg: '200px' }} height={{ xs: "44px", md: "47px" }} borderColor="#E54290" color="#E54290" borderRadius="10px" fontSize={{ xs: '13px', md: '16px' }} /> */}
              <a
                style={{ textDecoration: 'none' }}
                target="_blank"
                href={`https://www.google.com/maps?q=${mapCenter?.lat},${mapCenter?.lng}`}
              >  <ContainedButton text="Get Directions" height={{ xs: "44px", md: "47px" }} bgcolor="#E54290" width={{ xs: '170px', md: "200px", lg: '200px' }} borderRadius="10px" sx={{ display: 'flex', justifyContent: 'start', gap: 2 }} startIcon={<Directions sx={{ width: { xs: '20px', md: 32 }, height: { xs: '20px', md: 32 } }} />} fontSize={{ xs: '13px', md: '16px' }} /></a>
            </div>
          </Grid>
        </Grid>
        {/* <Typography
        level="h1"
              fontSize={30}
              sx={{
                textAlign: "center",
                fontWeight: "500",
                color: "#E54290",
                textTransform: "uppercase",
                marginTop: "20px",
              }}
      >
              Ayushman Bharat Facility ID & QR Code
            </Typography> */}

        {/* <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          // p: {
          //   xs: "0px",
          //   sm: "10px 10px 10px 25px",
          //   // md: "10px 65px 10px 90px",
          // },
          mt: "10px",
        }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Grid className="text-sm font-semibold">Ayushman Bharat Facility Id QR Code</Grid>
          <Grid className="text-sm font-medium">IN3610000013</Grid>
          <Grid container spacing={2} className="pt-6 flex-center flex" sx={{ justifyContent: { xs: 'center', sm: 'flex-start' }, flexDirection: { xs: 'column', sm: 'row' } }} alignItems={"center"}>
            <Grid item xs={12} sm={4} md={3}>
              {console.log(qr, 111)}
              <Box style={{ width: '100px', height: '100px' }} className="border border-inherit">{
                qr && <QRcode id="myqr" value={qr} size={100} />
              }</Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <div className="text-xs text-center">Scan this QR code Mobile Camera to Appointment with this facility using any Ayushman Bharat Application </div>
            </Grid>
          </Grid>
          <div style={{ paddingTop: '20px' }}>Address</div>
          <div className="text-sm font-medium pt-1">
            {orgData?.address?.line?.[0] ? `${orgData?.address?.line?.[0]}, ` : ''}
            {orgData?.address?.district ? `${orgData?.address?.district}, ` : ''}
            {orgData?.address?.state ? `${orgData?.address?.state}, ` : ''}
            {orgData?.address?.postalCode ? `${orgData?.address?.postalCode},` : ''}
          </div>
          <div style={{ paddingTop: '20px' }}>Phone</div>
          <div className="text-sm font-medium pt-1">{orgInfo?.telecom?.[0]?.value}</div>
          <div style={{ paddingTop: '20px' }}>Email</div>
          <div className="text-sm font-medium pt-1">{orgInfo?.telecom?.[1]?.value}</div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Card>
            <Box className="flex justify-between items-center">

              <div className="text-xs"><Card className="px-4 py-2 ">{selectedbranch?.name}</Card></div> */}
        {/* <div className="text-lg ">{locationBranch}</div> */}
        {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedbranch?.name}
                  label="Branch"
                  onChange={handleChange}
                  sx={{ border: '0.2px solid #A9A9A9', borderBottom: 'none', background: '#fff', paddingTop: '0px', '.MuiFilledInput-input': { padding: '5px 5px', minHeight: '20px', borderBottom: 'none' } }}
                  variant="filled"
                >
                  <MenuItem value={'Chandanagar'}>Chandanagar</MenuItem>
                  <MenuItem value={'Chintal'}>Chintal</MenuItem>
                  <MenuItem value={'Kompally'}>Kompally</MenuItem>
                  <MenuItem value={'Kothapet'}>Kothapet</MenuItem>
                  <MenuItem value={'Medipally'}>Medipally</MenuItem>
                  <MenuItem value={'Bachupally'}>Bachupally</MenuItem>
                </Select>
              </FormControl> */}
        {/* <div className="text-xs cursor-pointer"><Card className="px-2 py-2">
                <a
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  href={`https://www.google.com/maps?q=${mapCenter?.lat},${mapCenter?.lng}`}
                >
                  Get Directions

                </a>
              </Card></div>
            </Box>
            <Box sx={{ mt: "5px" }}>
              {
                mapCenter &&
                <LoadScript googleMapsApiKey="AIzaSyBoKERaSenAxv2oAp-Ma3qNVDlJIwh0UY8">
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={mapCenter}
                    zoom={18}
                  >
                    <Marker position={mapCenter} />
                  </GoogleMap>
                </LoadScript>

              } */}
        {/* { lat: orgData?.position?.longitude, lng: orgData?.position?.latitude } */}
        {/* <MapLocation

                locationBranch={locationBranch}
                lat={latitude} lng={longitude}
              // locations={locations?.filter((item, i) => item?.[0]?.toLocaleLowerCase() === locationBranch?.toLocaleLowerCase())}
              // center={center} zoom={zoom}
              // locations={["kompally", 17.518422339582578, 78.48276319462754]}
              /> */}
        {/* </Box>

          </Card >
        </Grid >
      </Grid > */}
      </Box >
    </div>

  );
};

export default FacilityInformation;
